#notice-copy{
    @media (min-width: 640px) and (max-width: 1266px) {
        padding-left : 2.5rem;
        padding-right : 2.5rem;
    }
    @media (min-width: 1024px) and (max-width: 1266px) {
        padding-left :3.125rem;
        padding-right :3.125rem;
    }
    @media (min-width: 1200px) and (max-width: 1266px) {
        padding-left : 3.75rem;
        padding-right : 3.75rem;
    }
}

@media (min-width:1441px){
    .body-socal > .grid-container.full{
        padding-right: 3.75rem;
        padding-left: 3.75rem;
    }
    .container-wrapper{
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        .grid-padding-x > .cell:not(.filter-sidebar):not(.content-detail):not(.group-content)
        {
            padding: 0 !important;
        }
    }
    // main.body-socal .about-us .bg-graphic {
    //     background-position-x: -10vw;
    // }
    main.body-socal .auth-socal .large-7 {
        background-size: 90%;
        background-position: center top 140px;
    }
}

@media (min-width:1200px) {
    #heading.header-org .breadcrumb .profile-pic {
        padding-bottom: unset;
        width:270px;
        height:270px;
    }
}

@media (max-width:1023px) {
    main.body-socal .dashboard-socal .mt-80 {
        margin-top: 30px;
    }
    .some-w-hide a span {
        display: none;
    }
    .m-hide {
        display: none;
    }
    
    .body-socal .sticky.is-stuck {
        position: relative!important;
        margin-top: unset!important;
    }
    .body-socal .sticky.is-anchored {
        top:unset!important;
    }
    main.body-socal .connect-group .profile#join {
        margin-bottom: 0;
        margin-top:60px;
    }
    main.body-socal .connect-group .profile#pricing {
        margin-bottom: 0;
    }

    main.body-socal .about-us .curated .committee-container .committee-list#testi:first-child {
        margin-top: 20px;
    }
}

@media (min-width: 1024px) {
    .desk-hide {
        display: none!important;
        .space-30 {
            display: none!important;
        }
    }
}

@media (min-width:1024px) and (max-width: 1340px) {
    main.body-socal .home-highlight .regular-carousel .circle {
        width:95%;
        height:0;
        padding-bottom: calc(95% - 2px);
    }
    main.body-socal .home-highlight.stat-socal .bg-graphic-join {
        background-position: 280px 100px;
    }
    main.body-socal .home-highlight .bg-graphic-highlight {
        background-position: 310px -240px;
    }
    main.body-socal #banner .grid-x .bg-graphic-home {
        background-position-x: 160px;
    }
}