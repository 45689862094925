@import './global/variable';

header.header-socal {
    position: relative;
    width:100%;
    z-index: 99;
    .topbar-socal {
        background-color: $dark-blue;
        .top-bar {
            background-color: $dark-blue;
            ul {
                background-color: $dark-blue;
                list-style-type: none;
                padding: 0;
                margin: 0;
                list-style: none;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                li {
                    color: $menu-color;
                    padding: 12.5px 15px;

                    a {
                        color: $menu-color;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: left;
                        font-family: $halyard;
                        font-weight: 300;
                        height: 20px;
                        font-style: normal;
                        display: inline;
                        padding: 0;
                    }

                    a.search-toggle {
                        .fa-search {
                            transform: scaleX(-1);
                            margin-left: rem-calc(3);
                            font-size: 14px;
                        }
                    }
                }

                li:last-child {
                    padding-right: 0;

                    a {
                        background-color: $orange;
                        padding: 4px 20px;
                        padding-bottom: 5px;
                        line-height: 20px;
                        border-radius: 20px;
                        font-size: 14px;
                    }
                }
            }
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }

    .menu-socal {
        padding-top: rem-calc(19);
        padding-bottom: rem-calc(19);
        background:#fff;
        z-index: 3;
        
        @media (min-width: 767px) {
            padding-top: rem-calc(24);
            padding-bottom: rem-calc(24);
        }

        img {
            width:100%;
        }

        ul {
            list-style-type: none;
            padding:0;
            margin:0;
            margin-top:10px;
            float: right;

            li {
                display: inline;

                a {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 1.2px;
                    text-align: center;
                    font-family: $halyard;
                    font-weight: 600;
                    color:$dark-blue;
                    padding: 9.5px 0;
                    text-transform: uppercase;
                    &:hover {
                    border-bottom: 1px solid $dark-blue;
                    }
                }

                a.active {
                    border-bottom: 1px solid $dark-blue;
                }
            }

            li + li {
                @include fluid-props(15px, 30px, margin-left, 1334px, 1440px);
            }

            @media screen and (max-width: 1266px) {
                display: none;
            }
        }

        .hamburger-menu{
            display: none;
            .icon {
                .line-icon {
                    display: inline-block;
                }
                .x-icon {
                    display: none;
                }
                &.close-icon {
                    .line-icon {
                        display: none;
                    }
                    .x-icon {
                        display: block;
                        margin-top: -2px
                    }
                }
            }
            @media screen and (max-width: 1266px) {
                display: inline-block;
                float: right;
            }
        }

        .brand {
            max-width: 211px;
            @media (min-width: 767px) {
                max-width: 405px;
                width:405px;
            }
            @media (max-width: 767px) {
                max-width: 211px;
                width: 211px;
            }
            a {
                background-image: url(../img/scg-logo-blue-2x.png);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 54px;
                float: left;
                
                @media (max-width: 767px) {
                    height: 28px;
                }
            }
            img {
                max-width: 211px;
                display: none;
                @media (min-width: 767px) {
                    max-width: 405px;
                }
                @media (max-width: 767px) {
                    max-width: 211px;
                }
            }
        }
    }

    .sticky.is-stuck {
        background-color: #fff;
        ul {
            li {
                a {
                    color: $dark-blue;
                    &.active {
                        border-color: $dark-blue;
                    }
                }
            }
        }
        .brand a {
            background-image: url(../img/scg-logo-blue-2x.png);
        }
    }
 
    .bg-purple {
        background: $art;
        ul {
            li {
                a {
                    color:#fff;
                    &.active {
                        border-color: #fff;
                    }
                }
            }
        }
    }

    .bg-blue {
        background: $economy;
        ul {
            li {
                a {
                    color:#fff;
                    &.active {
                        border-color: #fff;
                    }
                }
            }
        }
    }
    
    
    .bg-light {
        background-color:$menu-color;
    }
    .bg-trans {
        background-color:transparent;
        ul {
            li {
                a {
                    color:#fff;
                    &.active {
                        border-color: #fff;
                    }
                }
            }
        }
    }
    .container-menu-m {
        display: none;
    }

    @media (max-width: 1266px) {
        .container-menu-m {
            display: none;
            width:100%;
            height: 100%;
            position: fixed;
            top:0;
            left:0;
            background-color: #fff;
            overflow: hidden;
            overflow-y: scroll;
        }
        .wrapper-menu-m {
            position: absolute;
            top:66px;
            width: 100%;
            @media (min-width: 767px) {
                top: 100px;
            }
            @media (min-width: 1024px) {
                top: 150px;
            }
        }
        ul.menu-mobile{
            padding: 0;
            list-style: none;
            width: 100%;
            margin: 0px auto;
            padding: 0 1.375rem;
            @media (min-width: 640px) and (max-width: 1266px) {
                padding : 0 2.5rem;
                padding-bottom:40px;
            }
            @media (min-width: 1024px) and (max-width: 1266px) {
                padding : 0 3.125rem;
                padding-bottom:40px;
            }
            @media (min-width: 1200px) and (max-width: 1266px) {
                padding : 0 3.75rem;
                padding-bottom:40px;
            }
            padding-bottom:40px;
            position: relative;
            display: table;
            li {
                font-size: 14px;
                border-bottom:1px solid #324252;
                position: relative;
                width: 100%;
                vertical-align: sub;
                background: #fff;
                border-bottom: 1px solid $dark-blue;
                display: table;
                a{
                    text-decoration: none;
                    color: $dark-blue;
                    padding: 13px 0;
                    display: block;
                    box-sizing: border-box;
                    text-align: left;
                    font-weight: 600;
                    font-family: $halyard;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                    &:hover{
                       color: #000;
                    }
                 }
            }
            li + li {
                margin-left: 0;
            }
            .list-m{
               font-size: 14px;
               border-bottom:1px solid #324252;
               position: relative;
               width: 100%;
               vertical-align: sub;
               background: #fff;
               border-bottom: 1px solid $dark-blue;
               display: table;
               &:after{
                  content: "\f067";
                  font-family:$font-awesome;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  padding: 0px 5px;
                  color:$dark-blue;
                  font-size: 24px;
                  font-weight: 300;
               }
               a{
                  text-decoration: none;
                  color: #000;
                  padding: 13px 0;
                  display: block;
                  box-sizing: border-box;
                  text-align: left;
                  color: $dark-blue;
                  font-family: $halyard;
                  font-weight: 600;
                  &:hover{
                    color: $dark-blue;
                  }
               }
               .items{
                  height: 0px;
                  overflow: hidden;
                  li {
                     border:0;
                     padding-bottom: 10px;
                     &:first-child {
                         a {
                             padding-top:0;
                         }
                     }
                  }
                  a{
                     padding: 5px 0;
                     text-transform: unset;
                     color: $dark-blue;
                     font-weight: 300;
                     &:hover{
                        background-color: #3f5d79;
                        color:#fff;
                        transition:  300ms all;
         
                     }
                  }
               }
               &:last-child{
                  border-bottom:none;
               }
            }
            .active{
               &:after{
                  content: "\f068";
                  font-family:$font-awesome;
                  position: absolute;
                  right: 0;
                  top: 24px;
                  padding: 0px 5px;
                  color:$dark-blue;
                  font-size: 24px;
                  font-weight: 300;
               }
               >.items{
                  display: block;
                  background: #fff;
                  padding: 0px;
                  height: auto;
                  color:#000;
                  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
                  transition: all 200ms;
                  float: left;
                  margin-top: 0;
                  margin-bottom: 12px;
                  width: 100%;
                  li{
                     padding:0px;
                     list-style: none;
                  }
                  li:last-child{
                     border-color:transparent;
                     padding-bottom: 0px;
                  }
               }
               > a{
                  
                color: $dark-blue;
                  text-transform: uppercase;
                  font-weight: bold;
         
               }
               .list{
                  background: #fff;
                  a{
                     padding: 17px 0px 17px 0;
         
                  }
               }
            }
         }

         ul.rest-of-menu-m {
             display: block;
             width:100%;
             float: left;
             padding: 0 1.375rem;
             @media (min-width: 640px) and (max-width: 1266px) {
                padding : 0 2.5rem;
            }
            @media (min-width: 1024px) and (max-width: 1266px) {
                padding : 0 3.125rem;
            }
            @media (min-width: 1200px) and (max-width: 1266px) {
                padding : 0 3.75rem;
            }
             margin-left:0;
             background-color: #fff;
             li {
                 display: block;
                 text-align: left;
                 padding: 5px 0;
                 & + li {
                     margin-left:0;
                 }
                 a {
                    &.search-menu-m {
                        &::after {
                            content: "\f002";
                            font-family: $font-awesome;
                            font-weight: 300;
                            position: relative;
                            margin-left: 15px;
                        }
                    }
                     font-weight: 300;
                     text-transform: unset;
                     font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 1.2px;
                    text-align: left;
                    font-family: $halyard;
                    padding: 9.5px 0;
                    color: #0A00A0;
                 }
             }
         }
         .button-sign-in-m {
            width: 100%;
            float: left;
            padding: 0 1.375rem;
            @media (min-width: 640px) and (max-width: 1266px) {
                padding : 0 2.5rem;
            }
            @media (min-width: 1024px) and (max-width: 1266px) {
                padding : 0 3.125rem;
            }
            @media (min-width: 1200px) and (max-width: 1266px) {
                padding : 0 3.75rem;
            }
            margin-top: 30px;
            margin-bottom: 15px;
            a.btn-orange {
                display: block;
            }
        }
    }
}

     
