/* ===================================================================
  STORY DETAILS
=================================================================== */

/* Story Heading
------------------------------------------------------------------- */
.story-meta {
    color: $green;
    font-weight: 600;
    letter-spacing: 1.33px;
    line-height: 1em;
    margin-bottom: rem-calc(15);
}

.story-title {
    color: $dark-blue;
    font-family: $gellerMed;
    font-size: rem-calc(40);
    font-weight: 500;
    line-height: 1.1em;
    margin-bottom: rem-calc(10);
}

.story-subtitle {
    color: $black;
    font-size: rem-calc(23);
    letter-spacing: -0.19px;
    margin-bottom: rem-calc(30);
    font-family: $halyard;
}

/* Story Author
------------------------------------------------------------------- */
.story-authors {
    display: flex;
    align-items: flex-start;
    margin-bottom: rem-calc(60);
}

.story-author-item {
    & + & {
        margin-left: rem-calc(26);
    }

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        border: rem-calc(0.5) solid #ccc;
    }

    &__image {
        margin-right: rem-calc(8.75);
    }

    &__name {
        font-weight: 600;
        font-size: rem-calc(18);
        letter-spacing: -0.2px;
        font-family: $halyard;
    }
    &__title {
        font-family: $halyard;
    }
}

/* Story Image
------------------------------------------------------------------- */
.story-image {
    margin-bottom: rem-calc(10);
    font-weight: 300;

    &__caption {
        margin-top: rem-calc(9);
        color: $grey;
    }
}

/* Story Content
------------------------------------------------------------------- */
.story-nav {
    a {
        font-weight: 600;
        color: $orange;
        font-size: rem-calc(18);
        display: flex;
        align-items: center;
    }

    img {
        margin-right: 13px;
    }
}

.story-content {
    padding-bottom: rem-calc(60);
    margin-bottom: rem-calc(60);
    border-bottom: 1px solid $dark-blue;
}

/* Story Sidebar
------------------------------------------------------------------- */
.sidebar-widget {
    display: block;
    
    @include breakpoint(medium up) {
        margin-left: 30px;
    }
    
    .bookmark-button {
        display: block;
    }
}

.sidebar-widget-item {
    margin-top: 45px;
}

.sidebar-widget-item__title {
    font-family: $halyard;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: $black;
    margin-bottom: 10px;
    color: $dark-blue;
    text-transform: uppercase;
}

/* Social Share
------------------------------------------------------------------- */
.social-share {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;

    li {
        margin-right: 12px;
    }

    a {
        color: $black;
    }
}

/* Story Learn More
------------------------------------------------------------------- */

.story-learnmore {
    &:first-child {
        margin-top: 0;
    }
    margin-top: 20px;
    .img {
        width: 100%;
        height: 0;
        padding-bottom: calc(100% - 2px);
        border:1px solid #ccc;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    }
    .pl-15 {
        padding-left: 15px;
    }
    h4 {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        font-family: $halyard;
        font-weight: 600;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        font-family: $halyard;
        font-weight: 300;

    }
}
.findmoreby {
    label {
        font-size: 12px;
        line-height: 16px;
        font-family: $halyard;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        margin-top: 55px;
        margin-bottom: 20px;
    }
    border-bottom: 1px solid $dark-blue;
    padding-bottom: 60px;
}