main.body-socal .event-detail {
    .conference-socal.stories-content {
        .regist {
            &.speaker {
                .desc {
                    .regular-carousel-event {
                        .program-wrapper {
                            margin-bottom: 45px;
                        }
                    }
                }
            }
        }
    }
}

main.body-socal
.entry-content {
    .highlight {
        font-size: rem-calc(23);
        letter-spacing: -0.19px;
        line-height: 1.56em;
        margin-bottom: rem-calc(60);
    }
    p {
        font-size: rem-calc(18);
        line-height: 28px;
        letter-spacing: -0.2px;
        color: #000;
        font-family: $halyard;
        font-weight: 300;
        a {
            &:hover {
                color: #F26800;
                border-bottom: 1px solid #F26800;
            }
        }
    }
    ol {
        padding-left: 10px;
        li {
            font-family: $halyard;
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            text-align: left;
            color: $black;
            padding-left: 10px;
            &::marker {
                color: $dark-blue;
                font-weight: 600;
                padding-left: 10px;
            }
        }
    }
    ul {
        margin-top: 0px;
        display: inline-block;

        li {
            &::before {
                content: "\f105";
                font-family: $font-awesome;
                color: $dark-blue;
                position: absolute;
                top: 2px;
                left: 15px;
                font-weight: 400;
            }

            padding-left: 40px;
            font-family: $halyard;
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            text-align: left;
            color: $black;
            display: block;
            position: relative;
            margin-top:0;
            ul {
                list-style-type: square;
                li {
                    &::before {
                        content:"•";
                    }
                }
            }
        }
    }
    .video-wrapper {
        overflow:hidden;
        padding-bottom:56.25%;
        position:relative;
        height:0;
        margin-bottom: rem-calc(60);    
        iframe {
            left:0;
            top:0;
            height:100%;
            width:100%;
            position:absolute;
        }
    }

    blockquote {
        margin: rem-calc(68) 0;
        position: relative;
        &::before {
            content:'“';
            background-color: #00D4D5;
            position: absolute;
            left:-15px;
            top: -5px;
            height:48px;
            text-align: center;
            width:48px;
            border-radius: 50%;
            vertical-align: sub;
            z-index: 1;
            color: $dark-blue;
            font-family: $gellerMed;
            font-size: 55px;
            line-height: 70px;
        }
        p {
            color: $dark-blue;
            font-size: rem-calc(23);
            font-style: italic;
            letter-spacing: -0.26px;
            line-height: 1.56em;
            position: relative;
            z-index: 9;
            
        }

        cite {
            color: $dark-blue;
            font-size: rem-calc(16);
            font-weight: 600;
            line-height: 1.125;
            font-style: normal;
            text-transform: uppercase;
        }
    }

    hr {
        margin: rem-calc(60) 0;
        border-color: $dark-blue;
    }

    h1 {
        font-family: $gellerMed;
        font-size: rem-calc(32);
        font-weight: 500;
        line-height: 1.125;
    }

    h2 {
        font-size: rem-calc(23);
        font-weight: 600;
        line-height: 1.3em;
        margin-bottom: rem-calc(20);
    }

    h1 + h2,
    p + h2,
    ul + h2,
    ol + h2 {
        margin-top: rem-calc(60);
    }

    ul {
        margin-top: rem-calc(18);

        li + li,
        ul,
        ol {
            margin-top: rem-calc(15);
        }
    }

    ol {
        li + li {
            margin-top: rem-calc(20);
        }
    }
}