// Color
$menu-color: #FFFCF2;
$dark-orange:#F75F39;
$orange: #F26800;
$light-orange:#FFA000;
$mid-orange:#FFC000;
$dark-blue: #0A00A0;
$darker-blue:#110D51;
$light-blue: #00C0E6;
$alt-blue: #006CBD;
$green: #00A3A3;
$light-green : #96D379;
$tosca:#00D4D5;
$black: #000000;
$purple-alt: #90297D;
$purple: #9F4A9A;
$pink: #D06AA4;
$grey: #767676;
$red: #E34B49;
$light-grey: #CCCCCC;

//SCG ACCESSEBILITY NEW
$art: #AA2185;
$child: #B85900;
$economy: #2469FF;
$criminal: #946F00;
$disaster: #BC2228;
$education: #1D6ED4;
$environment: #007E55;
$equity:#900080;
$health: #0073AA;
$housing: #00797F;
$journalism:#C3418B;
$public:#00832A;
$youth:	#C73D00;
$aging: #C64A3F;

// Font
$halyard: halyard-text, sans-serif;
$gellerMed: geller-headline-medium,sans-serif;
$gellerMedItalic: geller-headline-medium-italic,
sans-serif;
$font-awesome: 'Font Awesome 5 Pro';

@font-face {
    font-family: "geller-headline-medium";
    src: url("../fonts/GellerHeadline-Medium/font.woff2") format("woff2"), url("../fonts/GellerHeadline-Medium/font.woff") format("woff");
    font-display: auto;
    font-style: normal;
}

@font-face {
    font-family: "geller-headline-medium-italic";
    src: url("../fonts/GellerHeadline-MediumItalic/font.woff2") format("woff2"), url("../fonts/GellerHeadline-MediumItalic/font.woff") format("woff");
    font-display: auto;
    font-style: normal;
}