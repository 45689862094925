@import './global/variable';

#heading {
    padding-top: 105px;
    @include breakpoint(medium down) {
        padding-top: 40px;
        padding-bottom: 30.5px;
    }

    &.heading-bg {
        padding-top: 30px;
        padding-bottom: 120px;
        margin-bottom: 60px;

        &.purple {
            background-color: $art;
        }

        .breadcrumb {
            .bg {
                padding-top: 80px;
                position: relative;

                .img-bg {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 430px;
                    z-index: 1;
                }
            }

            ul li,
            h1,
            p {
                color: $menu-color;
                z-index: 2;
                position: relative;
            }

            .conference-detail {
                margin-top: 40px;
                z-index: 2;
                position: relative;
            }

            h2 {
                font-size: 40px;
                font-family: $gellerMed;
                line-height: 44px;
                color: $menu-color;
                margin-top: 15px;
            }

            p {
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                margin-top: 40px;
            }

            .event-date {
                display: table;
                margin: auto;

                &:first-child {
                    padding-top: 6px
                }

                label {
                    font-size: 16px;
                    font-family: $halyard;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 1.33px;
                    text-transform: uppercase;
                    display: block;
                    text-align: center;
                    margin-bottom: 0;
                    color: $menu-color;
                }

                h2 {
                    font-size: 60px;
                    font-family: $gellerMed;
                    color: $menu-color;
                    line-height: 60px;
                    letter-spacing: -0.5px;
                    font-weight: 300;
                    text-align: center;
                    margin-top: 0px;
                }

                &:last-child {
                    position: relative;
                    padding-top: 15px;
                    margin: auto;
                    margin-top: 10px;
                    width: 50%;

                    &::before {
                        content: "";
                        border-top: 1px solid $menu-color;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        transform: rotate(-15deg);
                    }
                }
            }
        }
    }

    &.header-bg-curated {
        &.blue {
            background: url(../img/assets/scg-graphic-3@2x-alt.png), $economy;
            background-position: 50vw 20px;
            background-size: 800px;
            background-repeat: no-repeat;
            margin-bottom: 90px;
        }

        padding-top:0;
        background: url(../img/assets/scg-graphic-1@2x-alt.png),
        $art;
        background-position: 50vw 20px;
        background-size: 800px;
        background-repeat: no-repeat;
        margin-bottom:90px;

        .breadcrumb {
            height: 342px;

            ul li,
            h1,
            p {
                color: $menu-color;
            }
        }
    }

    &.header-org {
        .breadcrumb {
            ul {
                display: block;
            }

            label {
                font-family: $halyard;
                font-size: 16px;
                line-height: 22px;
                color: $dark-blue;
                font-weight: 600;
                text-transform: uppercase;
                margin-top: 20px;
                margin-bottom: 10px;
                display: inline-block;
            }

            a.btn-transparent {
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                margin-right: 20px;

                &.black {
                    border-color: $black;
                    color: $black;
                }
            }

            .profile-pic {
                width: 100%;
                height: 0px;
                padding-bottom: calc(100% - 2px);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 50%;
                border: 1px solid $light-grey;
                position: relative;
                @media (min-width: 1200px) {
                    
                    float: right;
                }

                a {
                    background: #fff;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    position: absolute;
                    font-size: 18px;
                    line-height: 25px;
                    text-transform: unset;
                    display: block;
                    padding: 6px 0;
                }
            }
        }
    }

    .breadcrumb {
        
        .resource-detail {
            margin-top: 40px;
            label {
                font-size: 16px;
                line-height: 16px;
                color: #90297D;
                letter-spacing: 1.33px;
                margin-bottom: 20px;
                font-family: $halyard;
                font-weight: 600;
                text-transform: uppercase;
            }
            .find-more {
                margin-top:35px;
                display: block;
                label {
                    color: $dark-blue;
                }
                a.btn-transparent-small {
                    margin-top: 5px;
                }
                .information {
                display: block;

                ul {
                    margin: 0;
                    display: inline-block;
                    margin-top: 28px;

                    li {
                        font-size: 16px;
                        line-height: 20px;
                        color: $black;
                        font-family: $halyard;
                        font-weight: 300;
                        display: inline-block;
                        margin-right: 20px;
                        text-transform: unset;
                        padding-left: 0;

                        &:first-child {
                            &::before {
                                content: "\f254";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 5px;
                            }
                        }

                        &:nth-child(2) {
                            &::before {
                                content: "\f3c5";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 4px;
                            }
                        }

                        &:nth-child(3) {
                            &::before {
                                content: "\f017";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 4px;
                            }
                        }

                        &:nth-child(4) {
                            &::before {
                                content: "\f0b1";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 4px;
                            }
                        }
                    }
                }
                }
                .author {
                    margin-top: 20px;
                    margin-bottom: 50px;

                    .author-img {
                        width: 60px;
                        height: 60px;
                        display: inline-block;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                        border:1px solid #ccc;
                    }

                    .author-info {
                        display: inline-block;
                        margin-left: 10px;

                        label {
                            font-size: 18px;
                            line-height: 26px;
                            font-family: $halyard;
                            font-weight: 600;
                            letter-spacing: -0.2px;
                            color: $black;
                            display: block;
                            text-transform: unset;
                            margin-bottom: 5px;
                        }

                        span {
                            font-size: 16px;
                            line-height: 20px;
                            font-family: $halyard;
                            font-weight: 300;
                            color: $black;
                            display: block;
                        }
                    }
                }
            }
        }
        &.dashboard-head {
            p {
                margin-top: 10px;
            }

            .divider-line {
                margin-top: 60px;
                margin-bottom: 17px;
            }

            a {
                background-color: transparent;
                color: $orange;
            }

            .profile-pic {
                width: 195px;
                height: 195px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                position: relative;
                border: 1px solid $light-grey;

                a {
                    background: #fff;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    position: absolute;
                }
            }
        }

        &.portal {
            p {
                font-size: 18px;
                line-height: 26px;
                margin-top: 35px;
            }
        }

        &.professional-learn {
            p {
                font-size: 23px;
                line-height: 32px;
                letter-spacing: -0.19px;
            }
        }

        .jobs-detail {
            p {
                margin-top: 15px;
            }

            .information {
                display: inline-block;

                ul {
                    margin: 0;
                    display: inline-block;
                    margin-top: 28px;

                    li {
                        font-size: 16px;
                        line-height: 20px;
                        color: $black;
                        font-family: $halyard;
                        font-weight: 300;
                        display: inline-block;
                        margin-right: 20px;
                        text-transform: unset;
                        padding-left: 0;

                        &:first-child {
                            &::before {
                                content: "\f254";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 5px;
                            }
                        }

                        &:nth-child(2) {
                            &::before {
                                content: "\f3c5";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 4px;
                            }
                        }

                        &:nth-child(3) {
                            &::before {
                                content: "\f017";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 4px;
                            }
                        }

                        &:nth-child(4) {
                            &::before {
                                content: "\f0b1";
                                font-family: $font-awesome;
                                color: $grey;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            margin-bottom: 30px;

            @include breakpoint(medium down) {
                margin-bottom: 10px;
            }

            li,
            a {
                font-family: $halyard;
                font-size: 16px;
                line-height: 22px;
                color: $dark-blue;
                font-weight: 600;
                display: inline;
                text-transform: uppercase;

                @include breakpoint(medium down) {
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }

        h1 {
            font-family: $gellerMed;
            font-size: 60px;
            letter-spacing: -0.5px;
            line-height: 64px;
            color: $black;
            margin-bottom: 0;

            @include breakpoint(medium down) {
                font-size: 45px;
                line-height: 48px;
            }
        }

        h2 {
            font-family: $gellerMed;
            font-size: 40px;
            line-height: 44px;
            color: $dark-blue;
        }

        h3 {
            font-family: $gellerMed;
            font-size: 32px;
            line-height: 36px;
            color: $black;
        }

        p {
            font-size: 23px;
            line-height: 32px;
            letter-spacing: -0.19px;
            font-family: $halyard;
            font-weight: 300;
            color: $black;
            margin-top: 45px;
            margin-bottom: 0;
        }

        label {
            font-size: 23px;
            line-height: 32px;
            letter-spacing: -0.19px;
            font-family: $halyard;
            font-weight: 300;
            color: $black;
            margin-top: 5px;
            display: block;

            @include breakpoint(medium down) {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.15px;
            }
        }

        .divider-line {
            width: 100%;
            display: block;
            border-top: 1px solid $dark-blue;
            margin-top: 62px;

            @include breakpoint(medium down) {
                margin-top: 30px;
            }
        }
        .mob-show {
            display: none;
        }
    }

    .jobs-socal {
        .group-inside {
            .resource-content {
                padding-left:0;
                .content-detail {
                    .large-6 {
                        width:50%;
                        &.img-r {
                            width:50%;
                        }
                    }
                    h4 {
                        font-family: $gellerMed;
                        font-size: 40px;
                        line-height: 44px;
                        color: $dark-blue;
                    }
                    p {
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                        font-family: $halyard;
                        font-weight: 300;
                        margin-bottom: 50px;
                        margin-top: 30px;
                    }
                    
                    label {
                        font-size: 16px;
                        line-height: 16px;
                        color: $dark-blue;
                        font-family: $halyard;
                        font-weight: 600;
                        text-transform:uppercase;
                    }
                    .btn-header {
                        margin-top: 13px;
                        a.btn-transparent {
                            display: inline-block;
                            background: #fff;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $halyard;
                            font-weight: 600;
                            border-radius: 15px;
                            border:1px solid #90297D;
                            padding: 5px 16px;
                            padding-bottom: 7px;
                            margin-top: 5px;
                            &.purp {
                                color: #90297D;
                                border-color: #90297D;
                            }
                            &.orgn {
                                color: #E34B49;
                                border-color: #E34B49;
                            }
                            
                        }
                    }
                    .information {
                        ul {
                            li {
                                font-size: 16px;
                                line-height: 20px;
                                text-transform: unset;
                                font-weight: 300;
                                color:#000;
                                display: inline-block;
                            }
                        }
                    }
                    .event-date {
                        position: relative;
                        display: block;
                        &:last-child {
                            margin-top:15px;
                        }
                        .line-diagonal {
                            margin-bottom:20px;
                            width: 100%;
                            border-top: 1px solid $dark-blue;
                            left: 0;
                            right:0;
                            height:1px;
                            transform: rotate(-20deg);
                            top: 0;
                        }
                        h2 {
                            font-size: 60px;
                            line-height: 64px;
                            letter-spacing: -0.5px;
                        }
                    }
                }
            }
        }
    }

    .d-show {
        display: none;
    }

    @include breakpoint(medium down) {
        &.heading-bg {
            padding-bottom: 30.5px;
            margin-bottom: 30px;
            padding-top: 40px;

            .breadcrumb {
                .conference-detail {
                    margin-top: 0;
                }

                .bg {
                    padding-top: 0;

                    .img-bg {
                        width: 150px;
                    }
                }

                h2 {
                    font-size: 30px;
                    line-height: 33px;
                }

                p {
                    margin-top: 15px;
                    font-size: 16px;
                    line-height: 23px;
                }

                .event-date {
                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        @media (max-width: 768px) {
                            width: 100%;
                            max-width: 95px;
                            padding-left: 40px;
                        }
                        &::before {
                            width: 1px;
                            border-left: 1px solid $menu-color;
                            height: 75%;
                            left: 25px;
                            transform: rotate(30deg);
                            top: unset;
                        }
                    }

                    display: inline-block;

                    h2 {
                        font-size: 45px;
                        line-height: 48px;
                    }

                    label {
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }
        &.header-bg-curated {
            &.blue {
                margin-bottom: 30.5px;
            }

            padding-top:40px;
            margin-bottom: 30.5px;
            background-position: 75px top;
            background-size: 100%;

            .breadcrumb {
                height: unset;
            }
        }
        &.header-org {
            .breadcrumb {
                .margin-top-3 {
                    margin-top:20px!important;
                }
                a{
                    &.btn-transparent.black {
                        font-size: 12px;
                        line-height: 16px;
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                }
                .profile-pic {
                    width: 200px;
                    height: 200px;
                    padding-bottom: 0;
                    a {
                        font-size: 16px;
                        line-height: 22px;
                        padding: 5px 0px;
                        padding-bottom: 7px;
                        display: block;
                        bottom:-2px;
                    }
                }
            }
        }

        .breadcrumb {   
            &.portal {
                p {
                    font-size: 16px;
                    line-height: 23px;
                    margin-top: 15px;
                }
            }

            h2 {
                font-size: 30px;
                line-height: 33px;
            }

            &.dashboard-head {
                a {
                    margin-top: 25px;
                    display: block;
                    font-size: 16px;
                    line-height: 22px;
                    padding: 5px 0;
                    padding-bottom: 7px;
                    &.btn-orange {
                        display: block;
                        margin-top: 15px;
                    }
                }
                .profile-pic {
                    width: 80%;
                    height: 0;
                    padding-bottom: calc(80% - 2px);
                }
                .margin-bottom-2 {
                    margin-bottom: 0!important;
                    h3 {
                        font-size: 24px;
                        line-height: 27px;
                        margin-top: 40px;
                    }
                }
                .margin-right-1 {
                    margin-right: 0!important;
                }
                .divider-line {
                    margin-top: 44px;
                    margin-bottom: 14px;
                }
            }

            &.professional-learn {
                p {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    margin-top: 15px;
                }
            }

            p {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.15px;
                margin-top: 15px;
            }

            .mob-hide {
                display: none;
            }
            .mob-show {
                display: block;
            }

            .jobs-detail {
                .information {
                    ul {
                        li {
                            width: 50%;
                            margin-right: 0;
                            float: left;
                            margin-bottom: 10px;
                            font-size: 14px;
                            line-height: 14px;

                            &:nth-last-child(-n+2) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .profile-pic {
            margin-top: 10px;

            img {
                padding: 0;
                margin-bottom: 25px;
            }
        }
        .d-show {
            display: block;
        }

        .jobs-socal {
            .group-inside {
                .resource-content {
                    .content-detail {
                        h4 {
                            font-size: 30px;
                            line-height: 33px;
                        }
                        p {
                            font-size: 16px;
                            line-height: 23px;
                            margin-top: 0;
                            margin-bottom: 40px;
                        }
                        label {
                            font-size: 14px;
                            line-height: 14px;
                        }
                        .btn-header {
                            a.btn-transparent {
                                font-size: 12px;
                                line-height: 15px;
                                &:first-child {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                        .information {
                            margin-bottom: 15px;
                            ul li {
                                font-size: 12px;
                                line-height: 16px;
                                display: inline-block;
                                margin-right: 15px;
                            }
                        }
                        .column-reverse {
                            flex-direction: column-reverse;
                            .d-show {
                                margin-bottom: 30px;
                            }
                            .small-10 {
                                padding-right: 0!important;
                                padding-left: 15px!important;
                            }
                        }
                        .large-6 {
                            width: 100%;
                            &.img-r {
                                width: 100%;
                                margin-bottom: 30px;
                            }
                        }
                        
                        .event-date {
                            h2 {
                                font-size: 40px;
                                line-height: 45px;
                            }
                            label {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width:1024px) {
        &.heading-bg {
            .conference-detail {
                .large-1 {
                    width: calc(9.33333% - 1.875rem);
                }
                .large-7 {
                    width: calc(59.33333% - 1.875rem);
                }
            }
            .breadcrumb {
                .bg .img-bg {
                    width: 200px;
                }
                .event-date {
                    &:last-child {
                        width:100%;
                        &::before {
                            width: 100%;
                            border-left: unset;
                            height: auto;
                            left: 0;
                            transform: rotate(-15deg);
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1024px) and (max-width:1368px) {
        .breadcrumb {
            &.dashboard-head {
               .profile-pic {
                   width: 90%;
                   height: 0;
                   padding-bottom: calc(90% - 2px);
                   a.btn-transparent {
                       font-size: 16px;
                       line-height: 22px;
                       padding: 5px 0;
                       display: block;
                       padding-bottom: 7px;
                   }
               } 
            }
        }
    }
    

    @include breakpoint(small down) {
        .breadcrumb {
            &.dashboard-head {
                .profile-pic {
                    width: 70%;
                    height: 0;
                    padding-bottom: calc(70% - 2px);
                }
            }
        }
    }
    
}

#heading.head-staff-detail {
    padding-top: 18px;
}

// home

main.body-socal {
    a {
        transition: all .1s ease-in;
    }
    &.mt-minus {
        margin-top: -40px
    }

    section {
        padding: 60px 0;
    }

    #banner {
        background-size: cover;
        background-position: center;
        position: relative;
        padding-top: 0;
        padding-bottom: 40px;
        &.banner-photo {
            margin-top: -103px;
            padding-top: 100px;
        }
        &.banner-photo::before {
            content: "";
            background-color: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }

        .grid-x {
            .banner-content {
                padding: 120px 0;
                padding-top: 85px;
                z-index: 2;

                h1 {
                    font-size: 60px;
                    line-height: 64px;
                    letter-spacing: -0.5px;
                    color: $menu-color;
                    font-family: $gellerMed;
                    margin-bottom: 35px;
                }

                h4 {
                    font-size: 23px;
                    font-family: $halyard;
                    line-height: 32px;
                    letter-spacing: -0.19px;
                    color: $menu-color;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    text-align: left;
                    font-family: $halyard;
                    color: $menu-color;
                    font-weight: 400;
                    margin-bottom: 37px;
                }

                a {
                    display: inline-block;
                    border: 1px solid $menu-color;
                    border-radius: 24px;
                    font-family: $halyard;
                    font-weight: 600;
                    line-height: 25px;
                    text-align: center;
                    color: $menu-color;
                    padding: 11px 25px;
                    padding-top: 9px;
                    &:hover {
                        background-color: $menu-color;
                        color:#000;
                    }
                }
            }

            .bg-graphic-home {
                position: absolute;
                right: 0px;
                bottom: 0;
                background-image: url(../img/assets/home-hero-graphic.svg);
                background-repeat: no-repeat;
                background-size: 100%;
                background-position-x: 50px;
                width: 758px;
                height: 560px;
            }
        }
    }

    .home-highlight {
        padding-top: 90px;
        padding-bottom: 90px;
        position: relative;

        .bg-graphic-highlight {
            position: absolute;
            right: 0px;
            top: 0;
            background-image: url(../img/assets/home-advance-graphic.svg);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 200px -240px;
            width: 703px;
            height: 100%;
        }

        &.tosca-bg {
            padding-top: 115px;
            padding-bottom: 115px;
        }

        &.stat-socal {
            padding-top: 143px;
            padding-bottom: 143px;

            .bg-graphic-join {
                position: absolute;
                right: 0px;
                bottom: 0;
                background-image: url(../img/assets/home-join-grapihc.svg);
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: 150px 100px;
                width: 787px;
                height: 100%;
            }

            h3 {
                font-size: 60px;
                font-family: $gellerMed;
                color: $black;
                text-align: center;
                letter-spacing: -0.5px;
                line-height: 64px;
            }

            label {
                font-family: $halyard;
                font-weight: 600;
                font-size: 18px;
                letter-spacing: -0.2px;
                line-height: 26px;
                text-align: center;
                color: $black;
            }

            .large-11 {
                padding: 15px 0;
                padding-bottom: 35px;
            }
        }

        h2 {
            color: $dark-blue;
            font-family: $gellerMed;
            font-size: 40px;
            line-height: 44px;
            text-align: left;
        }

        h3 {
            color: $black;
            font-family: $gellerMed;
            font-size: 32px;
            line-height: 36px;
            text-align: left;
            margin-top: 20px;
        }

        a {
            display: inline-block;
            border: 1px solid $dark-blue;
            border-radius: 24px;
            font-family: $halyard;
            font-weight: 600;
            line-height: 25px;
            text-align: center;
            color: $dark-blue;
            padding: 11px 25px;
            padding-top: 9px;
            margin-top: 30px;
            &:hover {
                background-color: $dark-blue;
                color: $menu-color;
            }

            &.dark-blue {
                border-color: $dark-blue;
                color: $dark-blue;
                background-color: transparent;
            }
        }

        p {
            font-family: $halyard;
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            text-align: left;
            margin-top: 20px;
        }

        .regular-carousel {
            width: 100%;
            margin-bottom: 0;

            .slick-dots {
                bottom: -67px;

                li {
                    width: 9px;
                    height: 9px;

                    button {
                        border: 1px solid $darker-blue;
                        border-radius: 50%;
                        width: 9px;
                        height: 9px;
                        padding: 0;
                    }

                    &.slick-active {
                        button {
                            background-color: $darker-blue;
                        }
                    }
                }
            }

            .circle {
                border: 1px solid $darker-blue;
                width: 257px;
                height: 257px;
                border-radius: 50%;
                position: relative;
                margin: 0 auto;
                cursor: pointer;
                display: block;
                padding:0;
                &:hover {
                    background-color: $darker-blue;
                    label {
                        span {
                            // border-bottom: 1px solid #110D51;
                            cursor: pointer;
                            color: #fff;
                        }
                    }
                }

                label {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;
                    color: $darker-blue;
                    font-size: 23px;
                    line-height: 30px;
                    font-family: $halyard;
                    font-weight: 600;
                }
            }
        }

        @include breakpoint(medium down) {
            padding-top: 60px;
            padding-bottom: 25px !important;

            h2 {
                font-size: 30px;
                line-height: 33px;
            }

            p {
                font-size: 16px;
                line-height: 23px;
            }
        }
    }

    #event {
        padding-top: 120px;
        padding-bottom: 90px;
        @media (min-width:1025px ) {
            .bold-border {
                margin-bottom: 45px;
                &:nth-last-child(-n+3) {
                    margin-bottom: 0;
                }
            }
        }

        .title {
            margin-bottom: 61px;

            a {
                font-family: $halyard;
                font-size: 18px;
                line-height: 25px;
                font-weight: 600;
                color: $orange;

                img {
                    margin-left: 14px;
                }
            }

            h2 {
                color: $dark-blue;
                font-family: $gellerMed;
                font-size: 40px;
                line-height: 44px;
                text-align: left;
                display: inline-block;
                margin-right: 20px;
            }
        }
    }
}

// checkout & auth

main.body-socal {
    .content-body-cart {
        padding-top: 30px;
        padding-bottom: 120px;

        .pb-200 {
            padding-bottom: 200px;
        }

        h3 {
            font-family: $gellerMed;
            font-size: 32px;
            line-height: 36px;
            color: $black;
            margin-bottom: 32px;

            @include breakpoint(medium down) {
                margin-bottom: 15px;
            }
        }

        p {
            font-family: $halyard;
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            color: $black;
        }

        span {
            font-family: $halyard;
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            color: $black;
            display: inline-block;
            margin-top: 5px;
        }

        .order-review-cart {
            @include breakpoint(medium down) {
                display: flex;
                flex-direction: column;
            }

            h3 {
                display: inline;
                margin-bottom: 0;
            }

            span {
                margin-top: 0;
                display: block;
            }

            a {
                font-size: 16px;
                font-family: $halyard;
                color: $orange;
                letter-spacing: -0.2px;
                line-height: 20px;
                margin-top: 10px;
                display: inline-block;
                margin-left: 20px;

                @include breakpoint(medium down) {
                    margin-left: 0;
                }
            }

            a.btn-transparent {
                margin-top: 60px;
            }
        }

        .cart {
            margin-top: 54px;

            @include breakpoint(medium down) {
                margin-top: 30px;
            }

            .cart-title {
                label {
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 1.33px;
                    font-family: $halyard;
                    font-weight: 600;
                    color: $dark-blue;
                    margin-bottom: 7px;
                }
            }

            .cart-list:first-child {
                padding-top: 0;
            }

            .cart-list {
                border-bottom: 1px solid #ccc;
                padding-bottom: 15.5px;
                padding-top: 15.5px;

                .large-4 {
                    span {
                        font-family: $halyard;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                        color: $black;
                    }
                }

                .custom-select {
                    width: 34px;
                    margin: 0;
                    margin-top: 5px;
                    display: inline-block;

                    .select-selected {
                        border-bottom: 0;
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    .select-selected:after {
                        top: 3px;
                    }

                    .select-items {
                        border-top: 1px solid $dark-blue;

                        div {
                            padding: 5px 10px;
                            cursor: pointer;
                        }
                    }
                }

                a {
                    font-size: 16px;
                    font-family: $halyard;
                    color: $orange;
                    letter-spacing: -0.2px;
                    line-height: 20px;
                    margin-top: 10px;
                    display: inline-block;
                }

                span {
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    color: $black;
                    display: inline-block;
                    margin-top: 5px;
                }
            }

            .cart-total {
                margin-top: 13.5px;

                h5 {
                    font-size: 23px;
                    line-height: 30px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 600;
                }

                h4 {
                    font-family: $gellerMed;
                    font-size: 32px;
                    line-height: 36px;
                    color: $black;
                }
            }

            .btn-transparent {
                color: $dark-blue;
                border-color: $dark-blue;
                font-size: 18px;
                padding: 6px 24px;
                padding-bottom: 7px;
                margin-top: 45px;
                display: inline-block;
            }
        }

        .summary-cart {
            margin-left: 27px;

            .cart-total {
                margin-top: 20px;
            }
        }

        .mt-20 {
            margin-top: 20px;
        }

        .mt-15 {
            margin-top: 15px;
        }

        .mt-65 {
            margin-top: 65px;
        }

        .mt-90 {
            margin-top: 90px;
        }
    }

    // form

    .contact-info-socal {
        label {
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 1.33px;
            font-family: $halyard;
            font-weight: 600;
            color: $dark-blue;
            margin-bottom: 7px;
            text-transform: uppercase;
        }

        .custom-select {
            .select-selected {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }

        input {
            width: 100%;
            display: inline-block;
            border: 0;
            box-shadow: unset;
            padding-left: 0;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            color: $black;
            font-family: $halyard;
            font-weight: 300;
            border-bottom: 1px solid $dark-blue;
            margin-bottom: 45px;

            @include breakpoint(medium down) {
                margin-bottom: 30px;
            }
        }

        textarea {
            width: 100%;
            display: inline-block;
            border: 0;
            box-shadow: unset;
            padding-left: 0;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            color: $black;
            font-family: $halyard;
            font-weight: 300;
            border-bottom: 1px solid $dark-blue;
            margin-bottom: 45px;

            @include breakpoint(medium down) {
                margin-bottom: 30px;
            }
        }

        a.btn-transparent {
            margin-top: 0;
        }
    }

    // auth
    .auth-socal {
        padding-top: 0;
        padding-bottom: 0;

        .large-7 {
            background-image: url(../img/assets/scg-graphic-11-blue-2x.png);
            background-position: right 50px top 75px;
            background-repeat: no-repeat;
            background-size: 872px;
        }

        h1 {
            font-family: $gellerMed;
            font-size: 60px;
            line-height: 64px;
            letter-spacing: -0.5px;
            color: $black;
            margin-bottom: 11px;
        }

        p {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            font-family: $halyard;
            font-weight: 300;
            margin-bottom: 44px;
            color: $black;

            a {
                font-weight: 600;
                color: $orange
            }
        }

        .bg-white {
            @media (min-width: 1024px) {
                &.pl-0 {
                    padding-left: 0;
                }
            }
            padding-top: 224px;
            padding-bottom: 188px;
        }

        .contact-info-socal {
            label {
                margin-bottom: 0;
            }

            input {
                padding-bottom: 0;
            }

            a {
                &.btn-orange {
                    width: 100%;
                    display: block;
                }
            }

            .other-link {
                margin-top: 15px;

                a {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.2px;
                    color: $orange;
                    font-family: $halyard;
                    font-weight: 300;
                }
            }
        }
    }
}

// about us

main.body-socal {
    .about-us {
        padding-top: 17px;
        position: relative;

        .color-orange {
            color: $orange
        }

        &.dashboard-socal {
            .intro {
                h5 {
                    font-family: $halyard;
                    font-weight: 600;
                    line-height: 30px;
                    font-size: 23px;
                    color: $black;
                    margin-bottom: 35px;
                }

                div {
                    margin-bottom: 30px;
                    display: block;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                label {
                    font-family: $halyard;
                    font-weight: 600;
                    line-height: 16px;
                    font-size: 16px;
                    letter-spacing: 1.33px;
                    color: $dark-blue;
                    text-transform: uppercase;
                }

                p {
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    margin-top: 7px;
                    margin-bottom: 0;

                    a {
                        font-family: $halyard;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: -0.2px;
                        color: $orange;
                        margin-left: 10px;
                    }
                }

                a {
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.2px;
                    color: $orange;
                    display: block;
                    margin-top: 7px;
                }

                .container-box {
                    &:first-child {
                        margin-top: 18px;
                    }

                    display: block;
                    position: relative;
                    padding-left: 25px;
                    margin-bottom: 0;
                    margin-top:9px;
                    cursor: pointer;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 300;
                    text-transform: unset;
                    user-select: none;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;

                        &:checked~.checkmark {
                            background-color: #444;
                        }

                        &:checked~.checkmark:after {
                            display: block;
                        }
                    }

                    .checkmark {
                        position: absolute;
                        top: 6px;
                        left: 0;
                        height: 17px;
                        width: 16px;
                        background-color: transparent;
                        border: 2px solid #444;
                        border-radius: 2px;
                        margin-top: 0;

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        &:after {
                            left: 3px;
                            top: 0px;
                            width: 6px;
                            height: 11px;
                            border: solid #fff;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }

                    &:hover input~.checkmark {
                        background-color: #444;
                    }
                }
            }

            .event {
                &.member-event {
                    padding-top: 75px;

                    .bold-border {
                        margin-bottom: 0;
                    }

                    .member-groups {
                        &:first-child {
                            padding-top: 0;
                        }

                        border-bottom: 1px solid $light-grey;
                        padding-top: 20px;

                        h5 {
                            text-transform: uppercase;
                            color: $dark-blue;
                            letter-spacing: 1.33px;
                            font-size: 16px;
                            line-height: 16px;
                            font-family: $halyard;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }

                        label {
                            font-family: $halyard;
                            font-weight: 600;
                            font-size: 23px;
                            line-height: 30px;
                            text-align: left;
                            color: $black;
                            margin-bottom: 20px;
                        }
                    }
                }

                padding-top: 0;

                .order-history {
                    &.bookmark {
                        .green {
                            label {
                                color: $green;
                            }
                        }

                        .purple {
                            label {
                                color: $purple;
                            }
                        }

                        .blue {
                            label {
                                color: $dark-blue;
                            }
                        }

                        label {
                            text-align: left;
                            margin-bottom: 7px;
                        }
                    }

                    padding-bottom: 40px;
                    margin-bottom: 40px;
                    border-bottom: 1px solid $grey;

                    h2 {
                        color: $dark-blue;
                        font-family: $gellerMed;
                        font-size: 60px;
                        line-height: 64px;
                        letter-spacing: -0.5px;
                        text-align: center;
                        margin-bottom: 0;
                    }

                    h4 {
                        font-family: $halyard;
                        font-weight: 600;
                        font-size: 23px;
                        line-height: 30px;
                        color: $black;
                        margin-bottom: 10px;
                    }

                    label {
                        font-family: $halyard;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 1.33px;
                        color: $dark-blue;
                        text-transform: uppercase;
                        margin-bottom: 0px;
                        text-align: center;
                    }

                    p {
                        font-family: $halyard;
                        font-weight: 300;
                        font-size: 18px;
                        letter-spacing: -0.2px;
                        line-height: 26px;
                        margin-bottom: 0;
                    }

                    .atc {
                        p {
                            font-family: $halyard;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 20px;
                        }

                        a {
                            font-family: $halyard;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 20px;
                            margin-top: 10px;
                            color: $orange;
                            display: block;
                        }
                    }
                }

                &.grantmaking {
                    .member-groups {
                        h5 {
                            margin-bottom: 15px;
                        }

                        .btn-transparent-small {
                            margin-right: 15px;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }

        .bg-graphic {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-35%) rotate(180deg);
            background-image: url(../img/assets/equity-bg-graphic.svg);
            background-repeat: no-repeat;
            background-size: 100%;
            width: 814px;
            height: 825px;
            background-position-x: -500px;
            z-index: -1;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin: 5px 0;
                float: left;
                width: 100%;
                display: block;

                a {
                    &.orange-active {
                        color: $orange;
                        text-transform: unset;
                        font-size: 18px;
                        line-height: 25px;
                        font-weight: 600;
                        margin-bottom: 20 px;
                        display: inline-block;

                        img {
                            margin-right: 13.5px;
                            margin-top: -3px;
                        }
                    }

                    color: $dark-blue;
                    font-family: $halyard;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 32px;
                    text-transform: uppercase;
                    padding: 5px 0;
                }

                a.active {
                    border-bottom: 1px solid $dark-blue;
                }

                a:first-child {
                    padding-top: 0;
                }
            }

            li:first-child {
                margin-top: 1px;
            }
        }

        .intro {
            p {
                font-family: $halyard;
                font-weight: 300;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                text-align: left;
                color: $black;
                margin: 12.5px 0;
                display: inline-block;

                &:first-child {
                    margin-top: 0;
                    font-size: 23px;
                    line-height: 32px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                a {
                    color: $orange;
                    font-weight: 600;
                }
            }

            ul {
                margin-top: 12.5px;
                display: inline-block;

                li {
                    &::before {
                        content: "\f105";
                        font-family: $font-awesome;
                        color: $dark-blue;
                        position: absolute;
                        top: 2px;
                        left: 15px;
                        font-weight: 400;
                    }

                    padding-left: 40px;
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    text-align: left;
                    color: $black;
                    display: block;
                    position: relative;
                    margin-top:0;
                }
            }
        }

        .desc {
            display: inline-block;

            p {
                font-family: $halyard;
                font-weight: 300;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                text-align: left;
                color: $black;
                margin-bottom: 34px;
            }
        }

        .committee-container {
            .committee-list {
                margin-top: 59.5px;

                h2 {
                    color: $dark-blue;
                    font-family: $gellerMed;
                    font-size: 40px;
                    line-height: 44px;
                    text-align: left;
                    margin-bottom: 30px;
                }

                p {
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    text-align: left;
                    color: $black;
                    margin-bottom: 34px;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        width: 50%;
                        display: inline-block;
                        margin: 0;
                        margin-bottom: 15px;

                        label {
                            font-family: $halyard;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 26px;
                            letter-spacing: -0.2px;
                            text-align: left;
                            color: $black;
                            margin-bottom: 4px;
                        }

                        p {
                            font-family: $halyard;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 26px;
                            letter-spacing: -0.2px;
                            text-align: left;
                            color: $black;
                        }
                    }
                }

                .divider-line {
                    margin-top: 44.5px;
                }
            }

            .committee-list:first-child {
                margin-top: 100px;
            }

            .committee-list:last-child {
                padding-bottom: 60px;

                .divider-line {
                    display: none;
                }
            }
        }

        .equity-container {
            position: relative;

            .equity-list {
                margin-top: 60px;

                h2 {
                    color: $dark-blue;
                    font-family: $gellerMed;
                    font-size: 40px;
                    line-height: 44px;
                    text-align: left;
                    display: inline-block;
                    margin-bottom: 12px;
                }

                a {
                    font-family: $halyard;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    text-align: left;
                    color: $orange;
                    margin-left: 20px;

                    img {
                        margin-left: 10px;
                        margin-top:-3px;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 300;
                    margin-top: 10px;
                }

                .collab-detail {
                    margin-top: 33px;

                    h3 {
                        font-family: $gellerMed;
                        font-size: 32px;
                        line-height: 36px;
                        color: $black;
                    }

                    a {
                        margin-left: 0;
                    }
                }
            }

            .equity-list:first-child {
                margin-top: 90px;
            }

            .equity-list:last-child {
                .divider-line {
                    display: none;
                }
            }
        }

        .collab-container {
            margin-top: 120px;

            h2 {
                color: $dark-blue;
                font-family: $gellerMed;
                font-size: 40px;
                line-height: 44px;
                text-align: left;
                display: inline-block;
                margin-bottom: 12px;
            }

            a {
                font-family: $halyard;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                text-align: left;
                color: $orange;

                img {
                    margin-left: 10px;
                    margin-top: -3px;
                }
            }

            p {
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                color: $black;
                font-family: $halyard;
                font-weight: 300;
                margin-top: 10px;
                margin-bottom: 19px;
            }

            .collab-detail {
                margin-top: 45px;

                h3 {
                    font-family: $gellerMed;
                    font-size: 32px;
                    line-height: 36px;
                    color: $black;
                    margin-bottom: 0;
                }

                span {
                    font-size: 16px;
                    line-height: 18px;
                    color: $grey;
                    font-family: $halyard;
                    font-weight: 300;
                    margin-top: 21px;
                    display: inline-block;
                    margin-bottom: 15px;
                }

                .divider-line {
                    margin-top: 45px;
                }

                div>img {
                    margin-top: 20px;
                }
            }

            .collab-detail:last-child {
                .divider-line {
                    display: none;
                }
            }
        }

        .adv {
            .faq-container {
                margin-top: 120px;
            }

            .equity-list {
                display: inline-block;
                margin-top: 0;

                &:first-child {
                    margin-top: 60px;

                    h4 {
                        margin-top: 40px;
                    }
                }

                h4 {
                    font-family: $gellerMed;
                    font-size: 32px;
                    line-height: 36px;
                    color: $black;
                }

                label {
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 23px;
                    line-height: 32px;
                    letter-spacing: -0.19px;
                    color: $black;
                    margin-top: 6px;
                    margin-bottom: 10px;
                }

                .divider-line {
                    margin: 45px 0;
                }
            }
        }

        .faq-container {
            margin-top: 0;
            margin-bottom: 60px;

            &.desc {
                p {
                    margin-bottom: 13px;
                    margin-top: 10px;
                }
            }

            h2 {
                color: $dark-blue;
                font-family: $gellerMed;
                font-size: 40px;
                line-height: 44px;
                text-align: left;
                display: inline-block;
                margin-bottom: 12px;
            }


        }

        .ideate-list {
            border-top: 1px solid $dark-blue;
            padding: 55px 0;

            &:last-child {
                margin-top: 13px;
            }

            h2 {
                color: $dark-blue;
                font-family: $gellerMed;
                font-size: 40px;
                line-height: 44px;
                text-align: left;
                display: inline-block;
                margin-bottom: 12px;
            }

            a {
                font-family: $halyard;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                text-align: left;
                color: $orange;

                img {
                    margin-left: 10px;
                }
            }

            p {
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                color: $black;
                font-family: $halyard;
                font-weight: 300;
                margin-top: 10px;
                margin-bottom: 19px;
            }

            label {
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 1.33px;
                font-family: $halyard;
                font-weight: 600;
                color: $dark-blue;
                text-transform: uppercase;
            }
        }

        .about-list {
            margin-top: 90px;

            h2 {
                color: $dark-blue;
                font-family: $gellerMed;
                font-size: 40px;
                line-height: 44px;
                text-align: left;
                margin-bottom: 20px;
            }

            a {
                font-family: $halyard;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                text-align: left;
                color: $orange;
                margin-left: 20px;

                img {
                    margin-left: 10px;
                }
            }

            p {
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                color: $black;
                font-family: $halyard;
                font-weight: 300;
                margin-top: 10px;
            }

            div {
                border-bottom: 1px solid #CCCCCC;

                label {
                    font-size: 23px;
                    line-height: 30px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 600;
                    margin-top: 20px;
                    display: inline-block;
                }

                p {
                    margin-bottom: 20px;
                }
            }

            div:last-child {
                border-bottom: 0px;
            }
        }

        .contact-style {
            padding-bottom: 112px;
            padding-top: 56px;
        }

        .orange-border {
            margin-top: 5px;
            margin-left: 29px
        }

        .event {
            padding-top: 100px;

            .blue-border {
                margin-bottom: 45px;
            }

            .title {
                margin-bottom: 25px;

                a {
                    font-family: $halyard;
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 600;
                    color: $orange;

                    img {
                        margin-left: 14px;
                    }
                }

                h2 {
                    color: $dark-blue;
                    font-family: $gellerMed;
                    font-size: 40px;
                    line-height: 44px;
                    text-align: left;
                    display: inline-block;
                    margin-right: 20px;
                }
            }

            .regular-carousel-3 {
                .slick-slide {
                    padding: 0 15px;
                }

                ul {
                    &.slick-dots {
                        top: -45px;
                        bottom: unset;
                        right: 15px;
                        width: unset;

                        li {
                            &:first-child {
                                margin-top: 0;
                            }

                            display: inline-block;
                            width:9px;
                            margin:0 5px;

                            &.slick-active {
                                button {
                                    background-color: $dark-blue;
                                }
                            }

                            button {
                                border: 1px solid $dark-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .pb-120 {
            padding-bottom: 60px;
        }

        .mt-80 {
            margin-top: 80px;
        }

        .mb-80 {
            margin-bottom: 80px;
        }

        @include breakpoint(medium down) {
            &.equity-style {
                padding-bottom: 171px;

                .bg-graphic {
                    width: 100%;
                    top: unset;
                    transform: rotate(180deg);
                    bottom: 0;
                    background-size: 85%;
                    background-position: bottom center;
                    height: 145px;
                }
            }

            padding-top:0;

            .about-list {
                margin-top: 60px;

                h2 {
                    margin-bottom: 10px;
                    font-size: 30px;
                    line-height: 33px;
                }

                div {
                    margin-bottom: 10px;

                    label {
                        font-size: 18px;
                        line-height: 23px;
                        margin-top: 10px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                }

            }

            .orange-border {
                margin: 0;

                label {
                    font-size: 18px;
                    line-height: 23px;
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: -0.18px;
                }

                a {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .equity-container {
                .equity-list:first-child {
                    margin-top: 60px;
                }

                .equity-list:last-child {
                    margin-bottom: 60px;
                }

                .equity-list {
                    margin-top: 44px;

                    h2 {
                        font-size: 30px;
                        line-height: 33px;
                        margin-bottom: 3px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 23px;
                        letter-spacing: -0.18px;
                    }

                    .equity-detail {
                        position: relative;
                        padding-bottom: 44px;

                        p {
                            margin-bottom: 0;
                        }

                        a {
                            position: absolute;
                            left: 0px;
                            bottom: 0;
                            margin-left: 0;
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    .divider-line {
                        margin-bottom: 44px;
                        margin-top: 44px;
                    }

                }
            }

            .curated {
                .committee-container {
                    .committee-list {
                        &:first-child {
                            margin-top: 60px;
                        }
                    }
                }
            }

            .committee-container {
                .committee-list {
                    &:first-child {
                        margin-top: 60px;
                    }

                    h2 {
                        font-size: 30px;
                        line-height: 33px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 23px;
                    }

                    .padding-right-3 {
                        padding-right: 0 !important
                    }

                    .regular-carousel-event {
                        ul.slick-dots {
                            li {
                                width: 9px;
                                margin: 0 5px;
                            }
                        }
                    }

                    ul li {
                        &:nth-of-type(odd) {
                            padding-right: 10px;
                        }

                        label {
                            font-size: 16px;
                            line-height: 23px;
                        }

                        span {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .intro {
                p:first-child {
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.15px;
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                }

                ul {
                    li {
                        font-size: 16px;
                        line-height: 23px;
                    }
                }
            }

            .adv {
                .faq-container {
                    margin-top: 60px;
                }

                .equity-container {
                    .equity-list {
                        &:first-child {
                            margin-top: 0;

                            h4 {
                                margin-top: 17px;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;

                            .equity-detail {
                                padding-bottom: 15px;
                            }
                        }

                        margin-top:0;

                        h4 {
                            font-size: 23px;
                            line-height: 30px;
                        }

                        label {
                            font-size: 18px;
                            line-height: 23px;
                        }

                        .divider-line {
                            margin-top: 20px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .faq-container {
                &:last-child {
                    margin-bottom: 15px;
                }
                h2 {
                    font-size: 30px;
                    line-height: 33px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                }

                .accordion {
                    label {
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: -0.2px;
                        margin-bottom: 0;
                        padding: 16px 0;
                        padding-right: 25px;
                    }

                    p {
                        padding-top: 6px;
                    }

                }
            }

            .ideate-list {
                &:last-child {
                    padding-bottom: 0;
                }

                padding: 45px 0;

                h2 {
                    font-size: 30px;
                    line-height: 33px;
                    margin-bottom: 5px;
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: -0.18px;
                }

                a {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }

    // bod & staff

    #bod-section {
        padding-top: 25px;

        ul {
            li:first-child {
                margin-top: 0;
            }
        }

        .custom-select.align-top {
            margin-top: -9px;
        }

        .bod-list {
            .large-3 {
                margin-bottom: 17px;

                div {
                    width: 100%;
                    height: 0px;
                    padding-bottom: calc(300 / 250 * 100%);

                    background-position: center;
                    background-size: cover;
                    position: relative;
                    // filter: sepia(100%) hue-rotate(180deg) contrast(90%) brightness(95%) saturate(4) grayscale(0.4);
                }

                div:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #001932;
                    mix-blend-mode: color;
                }

                div:hover:after {
                    mix-blend-mode: unset;
                    background-color: transparent;
                }

                label {
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: -0.2px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 600;
                    margin: 14px 0;

                    @include breakpoint(medium down) {
                        margin-top: 7px;
                        margin-bottom: 2px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 20px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 300;
                    margin-bottom: 7.5px;
                }
            }
        }

        @include breakpoint(medium down) {
            padding-top: 0;
            .bod-list {
                .large-3 {
                    label {
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 5px;
                    }
                    p {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    // staff detail

    .staff-detail {
        padding-top: 30px;

        ul {
            li {
                a {
                    color: $orange;
                    text-transform: unset;
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 600;

                    img {
                        margin-right: 13.5px;
                        margin-top: -3px;
                    }
                }
            }
        }

        .staff-contact {
            margin-bottom: 28px;
            margin-left: 30px;

            @include breakpoint(medium down) {
                margin: 0;
                margin-bottom: 30px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            label {
                font-size: 16px;
                text-transform: uppercase;
                line-height: 16px;
                letter-spacing: 1.33px;
                font-family: $halyard;
                font-weight: 600;
                color: $dark-blue;
            }

            a {
                font-size: 18px;
                color: $orange;
                font-family: $halyard;
                font-weight: 300;
                line-height: 26px;
                letter-spacing: -0.2px;
                margin-top: 2px;
            }
        }

        .staff-contact:first-child {
            margin-top: 5px;
        }
    }

    //related content
    #event.related-content {
        padding-top: 60px;
        padding-bottom: 89px;

        .title {
            margin-bottom: 50px;

            h2 {
                margin-bottom: 0;
            }
        }

        .regular-carousel-3 {
            .slick-slide>div {
                padding: 0 15px;
            }
        }

        @include breakpoint(medium down) {
            padding: 60px inherit;

            .title h2 {
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 33px;
            }

            a {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 22px;
            }
        }
    }

    // newsletter
    .newsletter-socal {
        padding-bottom: 0;

        .newsletter-inside {
            border-top: 1px solid $dark-blue;
        }

        .large-6 {
            border-bottom: 1px solid $dark-blue;

            &:nth-of-type(odd) {
                border-right: 1px solid $dark-blue;

                .content-inside {
                    padding-right: 60px;
                    padding-left: 0;
                }
            }

            .content-inside {
                padding-top: 70px;
                padding-left: 60px;
                padding-right: 15px;
                padding-bottom: 120px;

                label {
                    font-family: $halyard;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 1.33px;
                    color: $dark-blue;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    display: inline-block;
                }

                h3 {
                    font-size: 40px;
                    line-height: 44px;
                    font-family: $gellerMed;
                    color: $dark-blue;
                    margin-top: 5px;
                    margin-bottom: 0;
                }

                p {
                    font-size: 18px;
                    font-family: $halyard;
                    font-weight: 300;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    color: $black;
                    display: inline-block;
                    margin-top: 30px;
                    margin-bottom: 45px;
                }

                a {
                    &.btn-orange {
                        display: inline-block;
                        color: #fff;

                        &:hover {
                            color: $orange;
                        }
                    }

                    font-size: 18px;
                    font-family: $halyard;
                    font-weight: 600;
                    line-height: 25px;
                    color: $orange;
                    display: block;
                    margin: 5px 0;
                    span {
                        display: inline-block;
                    }

                    img {
                        margin-left: 10px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }

    //conferences
    .conference {
        padding-top: 0;

        .large-6 {
            &.bg-1 {
                border-bottom: 0;
                background: url(../img/bg/conference-mtm-thumbnail@2x.png), #9f4a9a;
                background-size: 100%;
                background-position: bottom right;
                background-repeat: no-repeat;

                @include breakpoint(medium up) {
                    border-bottom: 1px solid $dark-blue;
                }

                .content-inside {
                    h3 {
                        color: #fff;
                    }

                    h5 {
                        font-size: 23px;
                        color: #fff;
                        font-family: $halyard;
                        font-weight: 300;
                        line-height: 32px;
                        letter-spacing: -0.19px;
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }

                    a {
                        display: inline-block;
                        color: #fff;
                        padding: 6px 25px;
                        padding-bottom: 8px;
                        &:hover {
                            background-color: #fff;
                            color:#000;
                        }
                    }
                }
            }

            &.bg-2 {
                border-bottom: 0;
                background: url(../img/bg/conference-family-thumbnail@2x.png), #f75f39;
                background-size: 100%;
                background-position: bottom right;
                background-repeat: no-repeat;

                @include breakpoint(medium up) {
                    border-bottom: 1px solid $dark-blue;
                }

                .content-inside {
                    h3 {
                        color: #fff;
                    }

                    h5 {
                        font-size: 23px;
                        color: #fff;
                        font-family: $halyard;
                        font-weight: 300;
                        line-height: 32px;
                        letter-spacing: -0.19px;
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }

                    a {
                        display: inline-block;
                        color: #fff;
                        padding: 6px 25px;
                        padding-bottom: 8px;
                        &:hover {
                            background-color: #fff;
                            color:#000;
                        }
                    }
                }
            }

            .content-inside {
                padding-left: 60px;
                padding-right: 60px;
                padding-top: 70px;
                padding-bottom: 230px;

                h5 {
                    font-size: 23px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 300;
                    line-height: 32px;
                    letter-spacing: -0.19px;
                    margin-top: 30px;
                    margin-bottom: 30px;
                }

                a {
                    display: inline-block;
                    color: $dark-blue;
                    padding: 6px 25px;
                    padding-bottom: 8px;
                    &:hover {
                        background-color: $dark-blue;
                        color:$menu-color;
                    }
                }
            }

            &:nth-of-type(odd) {
                .content-inside {
                    padding-left: 60px;
                }
            }
        }
    }

    .conference-socal {
        .regist {
            .intro {
                border-top: 13px solid $dark-blue;
                border-bottom: 13px solid $dark-blue;
                padding-top: 45px;
                padding-bottom: 45px;
                margin-bottom: 120px;

                h3 {
                    font-size: 32px;
                    line-height: 36px;
                    color: $black;
                    font-family: $gellerMed;
                }

                .regist-column {
                    margin-top: 15px;
                    display: inline-block;

                    p {
                        margin-top: 0;
                    }

                    input {
                        margin-bottom: 10px;
                    }
                }

                .total {
                    margin-top: 50px;
                    display: inline-block;

                    h5 {
                        font-family: $halyard;
                        font-weight: 600;
                        font-size: 23px;
                        line-height: 30px;
                        color: $black;
                        margin-top: 0px;
                        margin-bottom: 0;
                    }

                    h4 {
                        font-family: $gellerMed;
                        font-size: 32px;
                        line-height: 36px;
                        color: $black;
                        margin-top: 0px;
                        margin-bottom: 0;
                    }

                    .dues {
                        padding-bottom: 30px;
                        border-bottom: 1px solid $light-grey;

                        span {
                            font-family: $halyard;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 26px;
                            letter-spacing: -0.2px;
                            color: $grey;
                            margin-top: 0px;
                            margin-bottom: 0;
                            text-transform: unset;
                        }
                    }

                    .details {
                        margin-top: 30px;

                        label {
                            font-family: $halyard;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 26px;
                            letter-spacing: -0.2px;
                            color: $grey;
                            margin-top: 0px;
                            margin-bottom: 0;
                            text-transform: unset;
                        }

                        span {
                            font-family: $halyard;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 26px;
                            letter-spacing: -0.2px;
                            color: $grey;
                            margin-top: 0px;
                            margin-bottom: 0;
                            text-transform: unset;
                        }
                    }
                }
            }

            &.agenda {
                .desc {
                    margin-bottom: 60px;

                    h4 {
                        font-weight: 300;
                        margin-bottom: 10px;
                    }

                    h3 {
                        margin-bottom: 10px;
                    }

                    span {
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: unset;
                    }

                    .accordion {
                        dt {
                            label {
                                span:first-child {
                                    width: 15%;
                                    display: inline-block;
                                    font-weight: 600;
                                }

                                span:last-child {
                                    width: 85%;
                                    display: inline-block;
                                    font-weight: 600;
                                }
                            }
                        }

                        p {
                            margin-left: 15%;
                            width: 85%;
                            display: none;
                        }
                    }
                }
            }

            &.sponsor {
                .desc {
                    &:last-child {
                        border: 0;
                    }

                    h3 {
                        margin-bottom: 60px;
                    }

                    h4 {
                        margin-bottom: 15px;
                    }

                    margin-bottom: 60px;
                    border-bottom: 1px solid $dark-blue;

                    .sponsor-list {
                        .cell {
                            margin-bottom: 60px;
                        }
                    }
                }
            }

            &.speaker {
                .desc {
                    h3 {
                        margin-bottom: 35px;
                    }

                    .speaker-content {
                        margin-bottom: 20px;

                        .large-3 {
                            width: 24%;
                        }

                        .large-9 {
                            width: 76%;
                        }

                        .img {
                            &.active {
                                border: 5px solid $orange;
                                padding-bottom: calc(85% - 10px);
                            }

                            width:85%;
                            height:0;
                            padding-bottom: 85%;
                            border-radius: 50%;
                            background-size: cover;
                            background-position: center;
                        }

                        .information {
                            .info-head {
                                display: block;

                                h4 {
                                    float: left;
                                    margin-bottom: 3px;
                                }

                                span {
                                    float: left;
                                    font-size: 13px;
                                    font-family: $halyard;
                                    font-weight: 600;
                                    line-height: 16px;
                                    color: $orange;
                                    border: 1px solid $orange;
                                    padding: 2px 5px;
                                    letter-spacing: normal;
                                    margin-top: 4px;
                                    margin-left: 10px;
                                    text-transform: uppercase;
                                }
                            }

                            label {
                                display: inline-block;
                                font-size: 16px;
                                font-family: $halyard;
                                font-weight: 300;
                                line-height: 20px;
                                width: 100%;
                            }

                            p {
                                line-height: 28px;
                                display: inline-block;
                                margin-top: 10px;
                            }
                        }
                    }

                    .regular-carousel-1 {
                        .slick-dots {
                            top: -45px;
                            bottom: unset;
                            right: 0;
                            width: unset;

                            li {
                                display: inline-block;
                                width: 9px;
                                margin: 0 5px;

                                &.slick-active {
                                    button {
                                        background-color: $dark-blue;
                                    }
                                }

                                button {
                                    border: 1px solid $dark-blue;
                                    border-radius: 50%;
                                    width: 9px;
                                    height: 9px;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }

            .desc {
                margin-bottom: 90px;
                display: block;

                h4 {
                    font-size: 23px;
                    font-family: $halyard;
                    font-weight: 600;
                    line-height: 30px;
                    color: $black;
                    margin-bottom: 20px;
                }

                h3 {
                    font-size: 32px;
                    line-height: 36px;
                    color: $black;
                    font-family: $gellerMed;
                    margin-bottom: 25px;
                }

                p {
                    margin-bottom: 15px;

                    a {
                        padding-bottom: 5px;
                        border-bottom: 1px solid $orange;
                        color: $black;
                    }
                }

                span {
                    font-size: 18px;
                    font-family: $halyard;
                    font-weight: 300;
                    line-height: 28px;
                    letter-spacing: -0.2px;
                    color: $black;
                    display: block;
                }

                .img-about {
                    margin-top: 30px;

                    img {
                        width: 100%;
                        display: block;
                        ;
                    }

                    label {
                        font-size: 16px;
                        line-height: 24px;
                        font-family: $halyard;
                        font-weight: 300;
                    }
                }
            }

            .committee-container {
                border-top: 1px solid $dark-blue;

                .committee-list {
                    margin-top: 60px;
                    margin-bottom: 0;
                }
            }

        }

        .jobs-sidebar {
            ul li {
                &:last-child {
                    border-bottom: 0;
                }
            }

            .btn-transparent {
                i {
                    margin-left: 7px;
                    font-size: 16px;
                }
            }

            .icon-sidebar {
                ul {
                    margin: 0;
                    display: inline-block;

                    li {
                        display: inline;
                        border: 0;
                        margin: 0 7px;
                        padding: 0;
                        width: unset;

                        &:first-child {
                            margin-left: 0;
                        }

                        a {
                            color: $black;
                        }
                    }
                }
            }

            .sub-menu-sidebar {
                ul {
                    margin: 0;
                    display: inline-block;

                    li {
                        display: block;
                        border: 0;
                        margin: 6px 0;
                        padding: 0;

                        &:first-child {
                            margin-top: 0;
                        }

                        a {
                            font-size: 16px;
                            line-height: 20px;
                            color: $black;
                            font-family: $halyard;
                            font-weight: 300;
                            text-transform: unset;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    //event 
    .event-style {
        ul {
            li {
                a {
                    color: $orange;
                    text-transform: unset;
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 600;

                    img {
                        margin-right: 13.5px;
                        margin-top: -3px;
                    }
                }
            }
        }

        .event-reg {
            h3 {
                font-family: $gellerMed;
                font-size: 32px;
                line-height: 36px;
                color: $black;
                margin-bottom: 0;

                span {
                    font-family: $gellerMedItalic;
                }
            }

            h4 {
                font-family: $halyard;
                font-size: 23px;
                line-height: 30px;
                color: $black;
                font-weight: 600;
                margin-top: 45px;
            }

            .contact-style {
                padding-top: 45px;
                padding-bottom: 60px;

                label {
                    margin-bottom: 1px;
                }

                .btn-transparent {
                    &.blue {
                        font-size: 18px;
                        padding: 6px 25px;
                        padding-bottom: 8px;
                    }
                }

                .container-box {
                    &:first-child {
                        margin-top: 18px;
                    }

                    display: block;
                    position: relative;
                    padding-left: 25px;
                    margin-bottom: 0;
                    margin-top:9px;
                    cursor: pointer;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    color: $black;
                    font-family: $halyard;
                    font-weight: 300;
                    text-transform: unset;
                    user-select: none;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;

                        &:checked~.checkmark {
                            background-color: #444;
                        }

                        &:checked~.checkmark:after {
                            display: block;
                        }
                    }

                    .checkmark {
                        position: absolute;
                        top: 6px;
                        left: 0;
                        height: 16px;
                        width: 16px;
                        background-color: transparent;
                        border: 1.5px solid #444;
                        border-radius: 50%;
                        margin-top: 0;

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        &:after {
                            left: 3px;
                            right: auto;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 6px;
                            height: 6px;
                            background-color: #fff;
                            border-radius: 50%;
                        }
                    }

                    &:hover input~.checkmark {
                        background-color: #444;
                    }
                }
            }
        }
    }

    .event-detail {
        .jobs-sidebar {
            ul li {
                &:last-child {
                    border-bottom: 0;
                }
            }

            .btn-transparent {
                i {
                    margin-left: 7px;
                    font-size: 16px;
                }
            }

            .icon-sidebar {
                ul {
                    margin: 0;
                    display: inline-block;

                    li {
                        display: inline;
                        border: 0;
                        margin: 0 7px;
                        padding: 0;
                        width: unset;

                        &:first-child {
                            margin-left: 0;
                        }

                        a {
                            color: $black;
                        }
                    }
                }
            }

            .sub-menu-sidebar {
                ul {
                    margin: 0;
                    display: inline-block;

                    li {
                        display: block;
                        border: 0;
                        margin: 6px 0;
                        padding: 0;

                        &:first-child {
                            margin-top: 0;
                        }

                        a {
                            font-size: 16px;
                            line-height: 20px;
                            color: $black;
                            font-family: $halyard;
                            font-weight: 300;
                            text-transform: unset;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .group-socal {
        padding-top: 0;
        padding-bottom: 0;

        .group-inside {
            border-top: 1px solid $dark-blue;
            margin: 0;

            .filter-sidebar {
                padding-top: 60px;
                padding-left: 0;
                width: 22.5%;
                &.khusus-scroll {
                    padding-top:0;
                    .wrapper-sticky {
                        padding-top: 60px;
                    }
                }
            }

            .group-content {
                border-left: 1px solid $dark-blue;
                padding-top: 60px;
                padding-right: 0;
                padding-bottom: 60px;
                width: 77.5%;

                .content-detail {
                    border-bottom: 1px solid $light-grey;
                    padding-bottom: 35px;
                    margin-bottom: 30px;

                    label {
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 1.33px;
                        font-family: $halyard;
                        font-weight: 600;
                        color: $dark-blue;
                        margin-bottom: 7px;
                        text-transform: uppercase;
                    }

                    h5 {
                        font-family: $halyard;
                        font-weight: 600;
                        font-size: 23px;
                        line-height: 30px;
                        color: $black;
                        margin-top: 10px;
                        margin-bottom: 0;
                    }

                    p {
                        font-family: $halyard;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 20px;
                        color: $black;
                        margin-top: 10px;
                        margin-bottom: 0;
                    }
                }
            }
        }

    }

    .connect-group {
        ul li .orange-active {
            margin-bottom: 25px;
        }

        .profile {
            margin-top: 66px;

            h2 {
                color: $dark-blue;
                font-family: $gellerMed;
                font-size: 40px;
                line-height: 44px;
                text-align: left;
                margin-bottom: 30px;
            }

            .profile-detail {
                .large-6 {
                    border-bottom: 1px solid $light-grey;
                    margin-bottom: 19px;
                }

                .large-12 {
                    border-bottom: 1px solid $light-grey;
                    margin-bottom: 20px;
                    &.mb-0 {
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 20px;
                    }
                }

                h5 {
                    font-family: $halyard;
                    font-weight: 600;
                    font-size: 23px;
                    line-height: 30px;
                    color: $black;
                    margin-top: 0px;
                    margin-bottom: 0;
                }

                label {
                    font-family: $halyard;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 1.33px;
                    color: $dark-blue;
                    text-transform: uppercase;
                    margin-bottom: 3px;
                }

                p {
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 18px;
                    letter-spacing: -0.2px;
                    line-height: 26px;
                    margin-bottom: 15px;
                }

                .author {
                    margin-top: 20px;
                    margin-bottom: 5px;

                    .author-img {
                        width: 85px;
                        height: 85px;
                        display: inline-block;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                    }

                    .author-info {
                        display: inline-block;
                        margin-left: 10px;

                        label {
                            font-size: 18px;
                            line-height: 26px;
                            font-family: $halyard;
                            font-weight: 600;
                            letter-spacing: -0.2px;
                            color: $black;
                            display: block;
                            text-transform: unset;
                            margin-bottom: 5px;
                        }

                        span {
                            font-size: 16px;
                            line-height: 20px;
                            font-family: $halyard;
                            font-weight: 300;
                            color: $black;
                            display: block;
                        }
                    }
                }

                .regular-carousel-1 {
                    &.mb-0 {
                        margin-bottom: 0;
                    }
                    .slick-slide {
                        .grid-x {
                            display: flex !important;
                        }
                    }

                    ul {
                        &.slick-dots {
                            top: -15px;
                            bottom: unset;
                            right: 15px;
                            width: unset;

                            li {
                                &:first-child {
                                    margin-top: 0;
                                }

                                display: inline-block;
                                width:9px;
                                margin:0 5px;

                                &.slick-active {
                                    button {
                                        background-color: $dark-blue;
                                    }
                                }

                                button {
                                    border: 1px solid $dark-blue;
                                    border-radius: 50%;
                                    width: 9px;
                                    height: 9px;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .portal-socal {
        .group-inside {
            .group-content {
                .pagination-list {
                    margin-top: 0;
                    margin-bottom: 55px;

                    ul {
                        display: inline-block;
                        margin: 0;

                        li {
                            margin: 0 8px;

                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }

                .content-detail {
                    .profile-img {
                        width: 80%;
                        height: 0;
                        background-size: cover;
                        background-position: center;
                        border-radius: 50%;
                        padding-bottom: 80%;
                    }

                    h5 {
                        margin-top: 0;
                    }
                }

                .logout {
                    p {
                        font-size: 18px;
                        font-family: $halyard;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                        color: $black;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    .jobs-socal {

        .jobs-content {
            display: inline-block;
            margin-top: 50px;

            .sub-content {
                margin-top: 5px;
                display: block;

                ul {
                    margin-top: 20px;
                }
            }

            h2 {
                font-size: 40px;
                line-height: 44px;
                font-family: $gellerMed;
                color: $dark-blue;
                margin-bottom: 24px;
                display: inline-block;
            }

            p {
                font-family: $halyard;
                font-weight: 300;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                color: $black;
                margin-top: 0;
            }

            h5 {
                font-size: 23px;
                font-family: $halyard;
                font-weight: bold;
                line-height: 30px;
                color: $black;
                margin-top: 0px;
                margin-bottom: 0;
                display: inline-block;
            }

            ul {
                margin-top: 0px;
                display: inline-block;

                li {
                    &::before {
                        content: "\f105";
                        font-family: $font-awesome;
                        color: $dark-blue;
                        position: absolute;
                        top: 2px;
                        left: 15px;
                        font-weight: 400;
                    }

                    padding-left: 40px;
                    font-family: $halyard;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    text-align: left;
                    color: $black;
                    display: block;
                    position: relative;
                    margin-top:0;
                }
            }



            a {
                color: $orange;
                font-family: $halyard;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                display: inline-block;
                position: relative;
                padding-right: 32px;
                margin-top: 5px;

                &::after {
                    content: "";
                    background-image: url(../img/assets/arrow-r-orange-2x.svg);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                }

                img {
                    margin-left: 10px;
                }
            }
        }

        .jobs-menu {
            ul {
                li {
                    .orange-active {
                        margin-bottom: 25px;
                    }
                }
            }
        }

        .jobs-sidebar {
            display: inline-block;
            margin-left: 30px;

            a {
                display: block;

                &.btn-transparent {
                    margin-top: 10px;

                    i {
                        margin-left: 7px;
                        font-size: 16px;
                    }
                }
            }

            ul {
                display: block;
                margin: 0;
                margin-top: 45px;

                li {
                    display: block;
                    margin-bottom: 20px;
                    margin-top: 0;
                    border-bottom: 1px solid $light-grey;
                    padding-bottom: 20px;

                    label {
                        font-family: $halyard;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                        color: $black;
                        margin-bottom: 10px;
                        color: $dark-blue;
                        text-transform: uppercase;
                    }

                    p {
                        margin: 0;
                        font-family: $halyard;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                    }
                }
            }

            .member-mentioned {
                .img-div {
                    .img {
                        width:80%;
                        height: 0;
                        padding-bottom: calc(80% - 2px);
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                        border:1px solid #ccc;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        .group-inside {
            .group-content {
                padding-top: 0;

                .content-detail {
                    padding-top: 45px;
                    padding-bottom: 45px;
                    margin-bottom: 0;
                    position: relative;

                    .badge-c {
                        position: absolute;
                        right: 19px;
                        top: -1px;
                        background-color: $orange;
                        color: $menu-color;
                        padding: 9px 16px;
                        font-size: 16px;
                        line-height: 20px;
                        font-family: $halyard;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                    a:hover {
                        h4, h5 {
                            color: $dark-blue;
                        }
                    }

                    h4 {
                        font-size: 32px;
                        line-height: 36px;
                        color: $black;
                        font-family: $gellerMed;
                        
                    }

                    h5 {
                        margin-bottom: 7px;
                    }

                    p {
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                        margin-top: 0;
                    }

                    .information {
                        display: inline-block;

                        ul {
                            margin: 0;
                            display: inline-block;
                            margin-top: 25px;

                            li {
                                font-size: 16px;
                                line-height: 20px;
                                color: $black;
                                font-family: $halyard;
                                font-weight: 300;
                                display: inline-block;
                                margin-right: 20px;
                                padding-left: 0;

                                &:first-child {
                                    &::before {
                                        content: "\f254";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 5px;
                                    }
                                }

                                &:nth-child(2) {
                                    &::before {
                                        content: "\f3c5";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }

                                &:nth-child(3) {
                                    &::before {
                                        content: "\f017";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }

                                &:nth-child(4) {
                                    &::before {
                                        content: "\f0b1";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .resource-content {
                padding-left: 40px;

                .search-desc {
                    padding-top: 45px;

                    p {
                        font-family: $halyard;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                        margin-bottom: 0;
                    }
                }

                .content-detail {
                    &.first-content {
                        .large-5 {
                            width: 43%;
                        }

                        .large-7 {
                            width: 57%;
                        }

                        .large-6 {
                            width: 48.6667%;
                        }

                        padding-top: 0;

                        .space-top {
                            padding-top: 45px;

                            h4 {
                                margin-bottom: 15px;
                            }

                            .event-date {
                                &:last-child {
                                    position: relative;
                                    padding-top: 15px;
                                    margin-top: 10px;

                                    &::before {
                                        content: "";
                                        border-top: 1px solid $dark-blue;
                                        position: absolute;
                                        top: 0;
                                        width: 100%;
                                        transform: rotate(-15deg);
                                    }
                                }
                            }
                        }
                    }

                    .event-date {
                        display: inline-block;

                        &:first-child {
                            padding-top: 6px
                        }

                        label {
                            font-size: 16px;
                            font-family: $halyard;
                            font-weight: 600;
                            line-height: 16px;
                            letter-spacing: 1.33px;
                            text-transform: uppercase;
                            display: block;
                            text-align: center;
                            margin-bottom: 0;
                        }

                        h2 {
                            font-size: 60px;
                            font-family: $gellerMed;
                            color: $dark-blue;
                            line-height: 60px;
                            letter-spacing: -0.5px;
                            font-weight: 300;
                            text-align: center;
                        }
                    }

                    &.grey {
                        h6 {
                            color: $grey
                        }
                    }

                    &.purple {
                        h6 {
                            color: $purple-alt
                        }
                    }

                    &.dark-blue {
                        h6 {
                            color: $dark-blue;
                        }
                    }

                    h6 {
                        color: $purple-alt;
                        font-family: $halyard;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 1.33px;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                    }

                    h5 {
                        margin-top: 0;
                    }

                    .img-r {
                        img {
                            width: 100%;
                        }
                    }

                    .information {
                        display: inline-block;
                        margin-top: 25px;

                        .cat-info {
                            display: block;

                            div {
                                display: inline-block;
                                margin-right: 18px;

                                &.purple {
                                    label::before {
                                        color: $equity;
                                    }
                                }

                                &.blue {
                                    label::before {
                                        color: $housing;
                                    }
                                }

                                &.red {
                                    label::before {
                                        color: $disaster
                                    }
                                }

                                &.yellow {
                                    label::before {
                                        color: $criminal;
                                    }
                                }

                                label {
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: $black;
                                    font-family: $halyard;
                                    font-weight: 300;
                                    letter-spacing: unset;
                                    text-transform: unset;
                                    display: inline-block;

                                    &::before {
                                        content: "\f111";
                                        font-family: $font-awesome;
                                        color: $purple-alt;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 5px;
                                        margin-left: -1px;
                                    }
                                }
                            }
                        }


                        ul {
                            margin: 0;
                            display: inline-block;

                            li {
                                font-size: 16px;
                                line-height: 20px;
                                color: $black;
                                font-family: $halyard;
                                font-weight: 300;
                                display: inline-block;
                                margin-right: 20px;
                                padding-left: 0;

                                &:first-child {
                                    &::before {
                                        content: "\f3c5";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 5px;
                                    }
                                }

                                &:nth-child(2) {
                                    &::before {
                                        content: "\f0a3";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }

                                &:nth-child(3) {
                                    &::before {
                                        content: "\f0c0";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }

                    .information-job {
                        display: inline-block;

                        ul {
                            margin: 0;
                            display: inline-block;
                            margin-top: 25px;

                            li {
                                font-size: 16px;
                                line-height: 20px;
                                color: $black;
                                font-family: $halyard;
                                font-weight: 300;
                                display: inline-block;
                                margin-right: 20px;
                                padding-left: 0;

                                &:first-child {
                                    &::before {
                                        content: "\f254";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 5px;
                                    }
                                }

                                &:nth-child(2) {
                                    &::before {
                                        content: "\f3c5";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }

                                &:nth-child(3) {
                                    &::before {
                                        content: "\f017";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }

                                &:nth-child(4) {
                                    &::before {
                                        content: "\f0b1";
                                        font-family: $font-awesome;
                                        color: $grey;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-weight: bold;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .stories-content {
                padding-left: 40px;

                .content-detail {
                    h6 {
                        color: $green;
                    }

                    .author {
                        margin-top: 15px;
                        margin-bottom: 5px;

                        .author-img {
                            width: 42px;
                            height: 42px;
                            display: inline-block;
                            border-radius: 50%;
                            background-position: center;
                            background-size: cover;
                        }

                        .author-info {
                            display: inline-block;
                            margin-left: 10px;

                            label {
                                font-size: 18px;
                                line-height: 26px;
                                font-family: $halyard;
                                font-weight: 600;
                                letter-spacing: -0.2px;
                                color: $black;
                                display: block;
                                text-transform: unset;
                                margin-bottom: 0;
                            }

                            span {
                                font-size: 16px;
                                line-height: 20px;
                                font-family: $halyard;
                                font-weight: 300;
                                color: $black;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .curated {
        .committee-container {
            &.mb-benefit {
                @include breakpoint(medium down) {
                    margin-bottom: 60px;
                }
                .committee-list {
                    .benefit {
                        margin-bottom: 0;
                    }
                }
            }
            .committee-list {
                &:first-child {
                    margin-top: 70px;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                padding-bottom: 0;


                .benefit {
                    position: relative;
                    .slick-list {
                        display: flex;
                        .slick-track {
                            display: flex;
                            .slick-slide {
                                flex:1;
                            }
                        }
                    }
                    .slick-slide > div {
                        height: 100%;
                    }
                    .benefit-detail {
                        height: 100%;
                        &.pink {
                            background-color: $art;
                        }

                        &.light-blue {
                            background-color: $economy;
                        }

                        &.light-orange {
                            background-color: $light-orange;
                        }

                        padding: 30px;
                        padding-bottom: 0;
                        h4 {
                            font-size: 32px;
                            line-height: 36px;
                            font-family: $gellerMed;
                            color: $menu-color;
                            min-height: 75px;
                            margin-bottom: 20px;
                        }

                        p {
                            font-family: $halyard;
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: -0.2px;
                            color: $menu-color;
                        }
                    }

                    &.regular-carousel-3 {
                        ul {
                            &.slick-dots {
                                top: -45px;
                                bottom: unset;
                                right: 60px;
                                width: unset;

                                li {
                                    &:first-child {
                                        margin-top: 0;
                                    }

                                    display: inline-block;
                                    width:9px;
                                    margin:0 5px;

                                    &.slick-active {
                                        button {
                                            background-color: $dark-blue;
                                        }
                                    }

                                    button {
                                        border: 1px solid $dark-blue;
                                        border-radius: 50%;
                                        width: 9px;
                                        height: 9px;
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .event {
                padding-top: 0;

                .bold-border {
                    label {
                        margin-bottom: 30px;
                    }
                }
            }

        }

        .member-curated {
            .member-detail {
                border-top: 1px solid $dark-blue;
                ;

                .author {
                    margin-top: 20px;
                    margin-bottom: 5px;

                    .author-img {
                        width: 61px;
                        height: 61px;
                        display: inline-block;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                    }

                    .author-info {
                        display: inline-block;
                        margin-left: 10px;

                        label {
                            font-size: 18px;
                            line-height: 26px;
                            font-family: $halyard;
                            font-weight: 600;
                            letter-spacing: -0.2px;
                            color: $black;
                            display: block;
                            text-transform: unset;
                            margin-bottom: 0;
                        }

                        span {
                            font-size: 16px;
                            line-height: 20px;
                            font-family: $halyard;
                            font-weight: 300;
                            color: $black;
                            display: block;
                        }
                    }
                }

                p {
                    margin-top: 25px;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 0;
                }
            }

            &.regular-carousel-3 {
                .slick-slide {
                    padding: 0 15px;
                }

                ul {
                    &.slick-dots {
                        top: -45px;
                        bottom: unset;
                        right: 15px;
                        width: unset;

                        li {
                            &:first-child {
                                margin-top: 0;
                            }

                            display: inline-block;
                            width:9px;
                            margin:0 5px;

                            &.slick-active {
                                button {
                                    background-color: $dark-blue;
                                }
                            }

                            button {
                                border: 1px solid $dark-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }
            }

            &.regular-carousel-4 {
                .slick-slide {
                    padding: 0 15px;
                }

                ul {
                    &.slick-dots {
                        top: -45px;
                        bottom: unset;
                        right: 15px;
                        width: unset;

                        li {
                            &:first-child {
                                margin-top: 0;
                            }

                            display: inline-block;
                            width:9px;
                            margin:0 5px;

                            &.slick-active {
                                button {
                                    background-color: $dark-blue;
                                }
                            }

                            button {
                                border: 1px solid $dark-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .event {
            padding-top: 90px;

            .title {
                margin-bottom: 40px;
            }

            .regular-carousel-3 {
                .slick-slide {
                    padding: 0 15px;
                }

                ul {
                    &.slick-dots {
                        top: -45px;
                        bottom: unset;
                        right: 15px;
                        width: unset;

                        li {
                            &:first-child {
                                margin-top: 0;
                            }

                            display: inline-block;
                            width:9px;
                            margin:0 5px;

                            &.slick-active {
                                button {
                                    background-color: $dark-blue;
                                }
                            }

                            button {
                                border: 1px solid $dark-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .pagination-list {
        margin-top: 30px;

        ul {
            padding: 0;
            display: table;
            margin: auto;

            li {
                margin: 0 10px;
                display: inline-block;

                &.active {
                    a {
                        border-bottom: 1px solid $dark-blue;
                    }
                }

                a {
                    color: $dark-blue;
                    font-family: $halyard;
                    font-size: 16px;
                    line-height: 22px;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 5px 0px;
                    text-align: center;
                    min-width: 10px;
                    display: inline-block;
                }
            }
        }
    }

    .space-30 {
        width: 100%;
        height: 30px;
        display: inline-block;
    }

    .space-60 {
        width: 100%;
        height: 60px;
        display: inline-block;
    }

    .space-375 {
        width: 100%;
        height: 375px;
        display: inline-block;

    }

    .hide-d {
        display: none;
    }

    .event-detail {
        .series-name {
            background-color: #f4f4f4;
            padding: 20px 24px;
            margin-bottom: 40px;
            i {
                font-weight: 600;
                color: $black;
            }
            span {
                font-family: $halyard;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: $black;
                margin-left:15px;
            }
        }
        .jobs-content {
            h3 {
                font-family: $gellerMed;
                font-size: 32px;
                line-height: 36px;
                margin-top: 25px;
            }
            .series-wrapper {
                margin-top: 15px;
                label {
                    font-family: $halyard;
                    font-size: 18px;
                    line-height: 25px;
                    color: $orange;
                    margin-bottom: 3px;
                    font-weight: 600;
                }
                p {
                    font-size: 18px;
                    line-height: 26px;
                    font-family: $halyard;
                    letter-spacing: -0.2px;
                }
            }
        }
        .conference-socal {
            .regist.speaker {
                .desc {
                    
                    span.more-text {
                        display: none;
                    }
                    span.more-dots {
                        display: inline;
                    }
                    .moreless-button {
                        margin-left: 0px;
                        font-weight: 600;
                    }
                    margin-top: 50px;
                    h3 {
                        color: $dark-blue;
                        font-size: 40px;
                        line-height: 44px;
                        margin-bottom: 40px;
                    }
                    .program-wrapper {
                        h4 {
                            font-size: 23px;
                            line-height: 30px;
                            font-family: $halyard;
                            font-weight: 600;
                        }
                        label {
                            position: relative;
                            margin-bottom: 15px;
                            display: block;
                            span {
                                font-size: 16px;
                                line-height: 16px;
                                letter-spacing: 1.33px;
                                font-family: $halyard;
                                font-weight: 600;
                                color: $purple-alt;
                                text-transform: uppercase;
                                padding-right: 10px;
                                background-color: #fff;
                                z-index: 9;
                                position: relative;
                                display: inline;
                            }
                            &::after {
                                content:"";
                                position: absolute;
                                right: 0;
                                background-color: $purple-alt;
                                height: 1px;
                                width:100%;
                                top:50%;
                                transform: translateY(-50%);
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .video-resource {
        width: 100%;
        height:400px;
        background-color: #ccc;
        margin-top: 30px;
        position: relative;
        label {
            font-size: 18px;
            line-height: 22px;
            color: #333;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }

    
    .committee-container {
        .committee-list {
            .regular-carousel-benefit {
        
                ul {
                    &.slick-dots {
                        top: -45px;
                        bottom: unset;
                        right: 15px;
                        width: unset;
        
                        li {
                            &:first-child {
                                margin-top: 0;
                            }
        
                            display: inline-block;
                            width:9px;
                            margin:0 5px;
        
                            &.slick-active {
                                button {
                                    background-color: $dark-blue;
                                }
                            }
        
                            button {
                                border: 1px solid $dark-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }
            }

        }
    }

    .story-comments {
        margin-bottom: 60px;
        h3 {
            color: #0A00A0;
            font-size: 40px;
            line-height: 44px;
            margin-bottom: 40px;
            font-family: $gellerMed;
        }
        a.btn-orange {
            display: block;
            font-size: 18px;
            margin-top: 15px;
        }
        .comment-list {
            border-top: 1px solid #ccc;
            padding-top: 20px;
            padding-bottom: 10px;
            &:first-child {
                border-top:0;
            }
            .large-11 {
                padding-left: 15px;
            }
            .img {
                width:100%;
                height: 0;
                padding-bottom: 100%;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
            }
            label {
                font-size: 18px;
                line-height: 26px;
                font-family: $halyard;
                font-weight: 600;
                display: block;
            }
            span {
                font-size: 16px;
                line-height: 18px;
                font-family: $halyard;
                font-weight: 300;
                display: block;
                color:#767676;
            }
            p {
                font-size: 18px;
                line-height: 26px;
                font-family: $halyard;
                font-weight: 300;
                display: block;
                margin-top: 15px;
            }
        }
    }


    // New Revision 
    .jobs-socal .jobs-content.mt-30 {
        margin-top: 30px;
    }

    .about-us .no-margin-first 
    {
        &.minus {
            ul {
                margin-top: -7px;
            }
        }
        ul {
            li:first-child {
                margin-top: 0;
            }
        }
    }
    
    .dashboard-portal {
        .desc {
            p {
                margin-bottom: 0;
            }
        }
    }

    @include breakpoint(small down) {
        .conference {
            .large-6 {
                &:nth-of-type(odd) {
                    border-right: 0;

                    .content-inside {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }
        }

        .newsletter-socal {
            .large-6 {
                &:nth-of-type(odd) {
                    border-right: 0;

                    .content-inside {
                        padding-right: 0;
                    }
                }
            }
        }

        .curated .committee-container .committee-list
        .regular-carousel-benefit {
            &.benefit {
                .benefit-detail {
                    padding:20px 20px;
                    padding-bottom: 10px;
                    h4 {
                        height: auto;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .about-us .collab-container .collab-detail div > img {
            display: none;
        }
    }

    @include breakpoint(medium down) {
        .d-hide {
            display: none;
        }
        #banner {
            .grid-x {
                .banner-content {
                    &.home-photo {
                        padding-bottom: 60px;
                    }
                    padding-top: 48px;
                    padding-right: 0!important;
                    padding-bottom: 140px;
                    h1 {
                        font-size: 45px;
                        line-height: 48px;
                        margin-bottom: 30px;
                    }
                    h4 {
                        font-size: 18px;
                        line-height: 24px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 23px;
                    }
                    a {
                        font-size: 16px;
                        line-height: 22px;
                        padding: 5px 20px;
                        padding-bottom: 7px;
                    }
                }
                .bg-graphic-home {
                    width: 100%;
                    background-size: 85%;
                    background-position: top center;
                    bottom: 0;
                    height: 140px;
                    top: unset;
                }
            }
        }

        .home-socal {
            .regular-carousel-event {
                a {
                    font-size: 16px;
                    line-height: 22px;
                    img {
                        margin-left: 10px;
                        display: inline-block;
                    }
                }
            }
        }

        .home-highlight {
            &.advancestyle {
                padding-top: 60px;
                padding-bottom: 180px!important;
                .bg-graphic-highlight {
                    width: 100%;
                    background-size: 85%;
                    background-position: top center;
                    bottom: 0;
                    height: 140px;
                    top: unset;
                }
                h2 {
                    margin-bottom: 25px;
                }
                h3 {
                    font-size: 24px;
                    line-height: 27px;
                    margin-bottom: 20px;
                }
                a {
                    font-size: 16px;
                    line-height: 22px;
                    padding: 5px 0;
                    padding-bottom:7px;
                    display: block;
                    margin-top: 10px;
                }
            }
            &.stat-socal {
                padding-top: 60px;
                padding-bottom: 180px!important;
                .bg-graphic-join {
                    width: 100%;
                    background-size: 85%;
                    background-position: top center;
                    bottom: 0;
                    height: 140px;
                }
                .large-11 {
                    padding-top:0;
                }
                .centermode {
                    .cell {
                        margin:auto;
                        width: 100%;
                        text-align: center;
                    }
                }
                a {
                    font-size: 16px;
                    line-height: 22px;
                    padding: 5px 0;
                    padding-bottom:7px;
                    display: block;
                    margin-top: 10px;
                }
                h3 {
                    font-size: 45px;
                    line-height: 48px;
                }
                label {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            &.tosca-bg {
                padding-top: 60px;
                padding-bottom: 90px!important;
                padding-left:0;
                padding-right: 0;
                .container-wrapper {
                    .grid-margin-x {
                        margin:0;
                    }
                }
                .regular-carousel {
                    margin-top:20px;
                    .slick-dots {
                        bottom: -40px;
                    }
                }
                h2, p {
                    padding: 0 20px;
                }
            }
            .regular-carousel {

                .slick-slide > div {
                    padding:0 10px;
                }
                .circle {
                    width:100%;
                    height:0;
                    padding-bottom: calc(100% - 2px);
                    label {
                        font-size: 18px;
                        line-height: 23px;
                    }
                }
            }
        }

        .conference {
            .large-6 {
                padding: 0 20px;

                // &:nth-of-type(odd) {
                //     border-right: 0;

                //     .content-inside {
                //         padding-left: 0px;
                //         padding-right: 0px;
                //     }
                // }

                .content-inside {
                    h5 {
                        margin: 15px 0;
                        font-size: 18px;
                        line-height: 23px;
                    }

                    a {
                        font-size: 16px;
                        line-height: 22px;
                        display: block;
                        padding: 5px 25px;
                        padding-bottom: 7px;
                        width: 100%;
                    }
                }
            }

            &.newsletter-socal {
                .large-6 {
                    &.bg-1 {
                        background-size: 170%;
                    }

                    &.bg-2 {
                        background-size: 130%;
                    }

                    .content-inside {
                        padding-left: 0px;
                        padding-right: 0px;
                        padding-top: 60px;
                        padding-bottom: 320px;
                        ;
                    }

                    .content-inside {
                        h5 {
                            margin: 15px 0;
                            font-size: 18px;
                            line-height: 23px
                        }

                        a {
                            font-size: 16px;
                            line-height: 22px;
                            display: block;
                            padding: 5px 25px;
                            padding-bottom: 7px;
                            width: 100%;
                        }
                    }
                }
            }

        }

        .newsletter-socal {
            padding-top: 0;

            .large-6 {
                // &:nth-of-type(odd) {
                //     border-right: 0;

                //     .content-inside {
                //         padding-right: 0;
                //     }
                // }

                .content-inside {
                    padding-top: 45px;
                    padding-bottom: 45px;
                    padding-left: 0;

                    label {
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: 1.17px;
                    }

                    h3 {
                        font-size: 30px;
                        line-height: 33px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 23px;
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }

                    a {
                        font-size: 16px;
                        line-height: 22px;

                        img {
                            margin-left: 5px;
                        }

                        &.btn-orange {
                            display: block;
                            font-size: 16px;
                            line-height: 22px;
                            padding: 5px 25px;
                            padding-bottom: 7px;
                        }
                    }
                }
            }
        }

        .group-socal {
            .group-inside {
                border: 0;

                .filter-sidebar {
                    width: 100%;
                    padding-top: 0;
                    padding-right: 0;
                    &.khusus-scroll {
                        height: unset!important;
                        .wrapper-sticky {
                            display: none;
                        }
                    }
                    &>.grid-x {
                        display: none;
                    }

                    .hide-d {
                        display: block;
                    }
                }

                .group-content {
                    width: 100%;
                    border-left: 0;
                    padding-left: 0;
                    padding-top: 42px;

                    .content-detail {
                        padding-bottom: 33px;

                        h5 {
                            font-size: 18px;
                            line-height: 23px;
                        }

                        label {
                            font-size: 14px;
                            line-height: 14px;
                            letter-spacing: 1.17px;
                        }
                    }
                }
            }
        }

        .pagination-list {
            ul {
                li {
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-size: 12px;
                        line-height: 17px;
                        min-width: 9px;
                    }
                }
            }
        }

        .staff-detail {
            padding-top: 20px;
        }

        .regular-carousel-3 {
            .slick-slide>div {
                padding: 0 10px;
            }

            ul.slick-dots {
                top: unset;
                bottom: -70px;
                right: 0;
                left: 0;
                margin: auto;

                li {
                    &:first-child {
                        margin-top: 0;
                    }

                    display: inline-block;
                    width:9px;
                    margin:0 5px;

                    &.slick-active {
                        button {
                            background-color: $darker-blue;
                        }
                    }

                    button {
                        border: 1px solid $darker-blue;
                        border-radius: 50%;
                        width: 9px;
                        height: 9px;
                        padding: 0;
                    }
                }
            }
        }

        .regular-carousel-event {
            .slick-slide>div {
                padding: 0 10px;
            }

            ul.slick-dots {
                top: unset;
                bottom: -45px;
                left: 50%;
                transform: translateX(-50%);
                margin: auto;
                width: auto;

                li {
                    &:first-child {
                        margin-top: 0;
                    }

                    display: inline-block;
                    width:9px;
                    margin:0 5px;

                    &.slick-active {
                        button {
                            background-color: $darker-blue;
                        }
                    }

                    button {
                        border: 1px solid $darker-blue;
                        border-radius: 50%;
                        width: 9px;
                        height: 9px;
                        padding: 0;
                    }
                }
            }
        }

        .about-us {
            &.dashboard-socal {
                padding-bottom: 30px;
                &.dashboard-portal {
                    padding-bottom: 60px;
                }
                .account-info {
                    .cell {
                        &.m-no-margin {
                            margin-bottom:0;
                        }
                        margin-bottom: 45px;
                        .intro {
                            h5 {
                                font-size: 18px;
                                line-height: 23px;
                                margin-bottom: 20px;
                            }
                            label {
                                font-size: 14px;
                                line-height: 14px;
                            }
                            p {
                                font-size: 16px;
                                line-height: 23px;
                            }
                            a {
                                font-size: 12px;
                                line-height: 16px;
                            }
                            .container-box {
                                margin-top: 12px;
                                .checkmark {
                                    top:0;
                                }
                            }
                        }
                    }
                }
                
                .event {
                    &.member-event {
                        padding-bottom: 60px;
                        padding-top:0;
                        &.tes {
                            padding-top:30px;
                        }

                        .title{
                            &.mt-mob {
                                margin-bottom: 0;
                            }
                        }
                        .member-groups {
                            h5 {
                                font-size: 14px;
                                line-height: 14px;
                            }
                            a.btn-transparent-small {
                                font-size: 12px;
                                line-height: 16px;
                            }
                            label {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                    .btn-transparent {
                        font-size: 16px;
                        line-height: 22px;
                        padding-bottom: 7px;
                    }
                    .order-history {
                        label {
                            font-size: 14px;
                            line-height: 14px;
                            text-align: left;
                        }
                        h2 {
                            font-size: 45px;
                            line-height: 48px;
                            text-align: left;
                            margin-bottom: 10px;
                        }
                        h4 {
                            font-size: 18px;
                            line-height: 26px;
                        }
                        p {
                            font-size: 16px;
                            line-height: 23px;
                        }
                        .atc {
                            p, a {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                }
                .regular-carousel-event ul.slick-dots {
                    bottom: -75px;
                }
            }
            .event {
                padding-top: 60px;

                .title {
                    margin-bottom: 30px;
                    h2 {
                        font-size: 30px;
                        line-height: 33px;
                        display: block;
                    }
                    a {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .regular-carousel-3 {
                    position: relative;

                    &.slick-slider {
                        margin-bottom: 0;
                    }

                    .slick-slide {
                        padding: 0;

                        &>div {
                            padding: 0 10px;
                        }
                    }

                    ul.slick-dots {
                        top: unset;
                        bottom: -40px;
                        right: unset;
                        left: 50%;
                        margin: auto;
                        width: auto;
                        transform: translateX(-50%);

                        li {
                            &:first-child {
                                margin-top: 0;
                            }

                            display: inline-block;
                            width:9px;
                            margin:0 5px;

                            &.slick-active {
                                button {
                                    background-color: $darker-blue;
                                }
                            }

                            button {
                                border: 1px solid $darker-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }
            }

            .desc { 
                p {
                    font-size: 16px;
                    line-height: 23px;
                }
                &.m-membership {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        #event {
            &.event-resource {
                .regular-carousel-event {
                    margin-bottom: 120px;
                }
            }
            padding-top:60px;
            .title {
                margin-bottom: 30px;
                h2 {
                    font-size: 30px;
                    line-height: 33px;
                    display: block;
                }
                a {
                    font-size: 16px;
                    line-height: 23px;
                }
            }
        }

        #event,
        .event {
            .space-60 {
                margin-bottom: 30px;
            }

            &.related-content {
                .regular-carousel-3 {
                    &.slick-slider {
                        margin-bottom: 0;
                    }

                    .slick-slide>div {
                        padding: 0 10px;
                    }

                    ul.slick-dots {
                        top: unset;
                        bottom: -45px;
                        right: 0;
                        left: 0;
                        margin: auto;

                        li {
                            &:first-child {
                                margin-top: 0;
                            }

                            display: inline-block;
                            width:9px;
                            margin:0 5px;

                            &.slick-active {
                                button {
                                    background-color: $darker-blue;
                                }
                            }

                            button {
                                border: 1px solid $darker-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }

                padding-left:20px;
                padding-right: 20px;

                .title {
                    margin-bottom: 30px;

                    h2 {
                        font-size: 30px;
                        line-height: 33px;
                        margin-right: 0;
                        display: block;
                    }

                    a {
                        font-size: 16px;
                        line-height: 22px;
                        margin-top: 14px;
                        display: block;
                    }
                }
            }
        }

        .event-style {
            .event-reg {
                h3 {
                    font-size: 23px;
                    line-height: 30px;
                }

                h4 {
                    font-size: 18px;
                    line-height: 23px;
                    margin-top: 30px;
                }

                .contact-style {
                    padding-top: 30px;
                    padding-bottom: 0;

                    label {
                        font-size: 14px;
                        line-height: 14px;
                    }

                    input {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .btn-transparent.blue {
                        font-size: 16px;
                        line-height: 22px;
                        padding: 5px 0;
                        padding-bottom: 7px;
                        display: block;
                    }

                    .container-box {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .space-375 {
                        display: none;
                    }
                }
            }
        }

        .auth-socal {
            position: relative;
            .bg-login {
                    width: 100%;
                    background-size: 85%;
                    background-position: top center;
                    bottom: 0;
                    height: 140px;
                    top: unset;
                    position: absolute;
            }
            .bg-white {
                padding-top: 38px;
                h1 {
                    font-size: 45px;
                    line-height: 48px;
                    margin-bottom: 25px;
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                }

                .contact-info-socal {
                    .other-link {
                        margin-top: 25px;

                        a {
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: -0.1px;
                        }

                        .text-right {
                            text-align: left;
                        }
                    }
                }
            }
        }

        .contact-info-socal {
            label {
                font-size: 14px;
                line-height: 14px;
            }

            input {
                font-size: 16px;
                line-height: 23px;
            }
        }

        .jobs-socal {
            &.bg-light {
                .jobs-content {
                    margin-top: 0;

                    .space-30 {
                        display: none;
                    }
                }
            }

            .group-inside {
                .group-content {
                    .grid-x>div {
                        &:nth-last-of-type(2) {
                            margin-bottom: 30px;
                        }
                    }

                    &.event-m-content {
                        .content-detail {
                            &.first-content {
                                .space-top {
                                    padding-top:20px;
                                }
                                padding-top:0;
                                padding-bottom: 15px;
                                .large-7 {
                                    width: 100%;
                                }
                                .img-r {
                                    width:100%;
                                    img {
                                        width:100%;
                                    }
                                }
                            }
                            padding: 45px 0;
                            .padding-left-1 {
                                padding-left: 0!important;
                            }

                            .column-reverse {
                                flex-direction: column-reverse;
                            }
                            h5 {
                                font-size: 18px;
                                line-height: 23px;
                                margin-bottom: 13px;
                            }
                            .event-date {
                                label {
                                    font-size: 12px;
                                    line-height: 15px;
                                }
                                h2 {
                                    font-size: 40px;
                                    line-height: 45px;
                                }
                            }
                            .information {
                                margin-top: 15px;
                                ul {
                                    li {
                                        font-size: 12px;
                                        line-height: 15px;
                                        width:auto;
                                        display: inline-block;
                                        margin-right: 20px;
                                        margin-bottom: 5px;
                                    }
                                }
                                .cat-info {
                                    div {
                                        label {
                                            font-size: 12px;
                                            line-height: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .content-detail {
                        padding: 30px 0;

                        &:first-child {
                            border-top: 1px solid $light-grey;
                        }

                        h4 {
                            font-size: 23px;
                            line-height: 30px;
                        }

                        .badge-c {
                            font-size: 12px;
                            line-height: 15px;
                            padding-top: 7px;
                            padding: 7px 14px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 23px;
                        }

                        .information {
                            ul {
                                li {
                                    margin-right: 0;
                                    width: 50%;
                                    float: left;
                                    margin-bottom: 10px;
                                    font-size: 14px;
                                    line-height: 14px;
                                }
                            }
                        }
                    }
                }
                .resource-content {
                    &.padding-left-3 {
                        padding-left: 0!important;
                    }
                    .search-desc {
                        padding-top: 0;
                        p {
                            font-size: 16px;
                            line-height: 23px;
                        }
                    }

                    .content-detail {
                        h6 {
                            font-size: 14px;
                            line-height: 14px;
                        }
                        h5 {
                            font-size: 16px;
                            line-height: 23px;
                        }
                        .author {
                            .author-img {
                                margin-bottom: 0!important;
                                width:40.5px;
                                height:40.5px;
                            }
                            .author-info {
                                label {
                                    font-size: 16px;
                                    line-height: 23px;
                                }
                                span {
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                        }

                        .information {
                            .cat-info {
                                margin-bottom: 5px;
                                div label{
                                font-size: 14px;
                                line-height: 16px;
                                }
                            }
                        }
                        .information-job {
                            ul li {
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }

            .jobs-content {
                margin-top: 60px;

                h2 {
                    font-size: 30px;
                    line-height: 33px;
                }

                h5 {
                    font-size: 18px;
                    line-height: 26px;
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                }

                ul {
                    li {
                        font-size: 16px;
                        line-height: 23px;
                        padding-left: 30px;

                        &::before {
                            left: 8px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                a {
                    font-size: 16px;
                    line-height: 23px;
                }
            }

            .jobs-sidebar {
                margin-left: 0;
                margin-top: 60px;

                >a {
                    font-size: 16px;
                    line-height: 23px;
                }

                ul {
                    li {

                        label,
                        p {
                            font-size: 16px;
                            line-height: 23px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .conference-socal {
            padding-bottom: 30px;

            .regist {
                &.speaker {
                    .desc {
                        .speaker-content {
                            .img {
                                &.active {
                                    padding-bottom: calc(80% - 10px);
                                }
                                width: 80%;
                                height: 0;
                                padding-bottom: 80%;
                                margin:auto;
                                margin-bottom: 15px;
                            }
                            .large-9 {
                                width: 100%;
                            }
                            .large-3 {
                                width: 100%;
                            }
                        }
                    }
                }
                &.agenda {
                    .desc {
                        .accordion {
                            p {
                                width: 100%;
                                margin-left: 0;
                            }

                            dt {
                                label {
                                    span {
                                        font-size: 14px;
                                        line-height: 18px;
                                        &:first-child {
                                            width: 100%;
                                        }
                                        &:last-child {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.sponsor {
                    .sponsor-list {
                        .cell {
                            margin-bottom: 30px;
                        }
                        img {
                            display: table;
                            margin:auto;
                        }
                    }
                }

                .intro {
                    margin-bottom: 60px;
                    padding-top: 30px;
                    padding-bottom: 30px;

                    h3 {
                        br {
                            display: none;
                        }
                    }

                    .total {
                        h5 {
                            font-size: 18px;
                            line-height: 26px;
                        }
                        h4 {
                            font-size: 24px;
                            line-height: 30px;
                        }
                        .details {
                            label {
                                font-size: 16px;
                                line-height: 23px;
                            }
                        }
                        .dues {
                            span {
                                font-size: 16px;
                                line-height: 23px;
                            }
                        }
                    }
                }

                .desc {
                    h4 {
                        font-size: 18px;
                        line-height: 26px;
                        margin-bottom: 15px;
                    }

                    margin-bottom: 60px;
                }
            }

            .jobs-sidebar {
                margin-top: 0;

                ul {
                    margin-top: 30px;

                    li {
                        &:last-child {
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .curated {
            .member-curated {
                .member-detail {
                    p {
                        font-size: 12px;
                        line-height: 16px;
                        margin-top: 15px;
                    }
                    .author {
                        margin-top: 10px;
                        .author-img {
                            width:40.5px;
                            height: 40.5px;
                        }
                        .author-info {
                            label {
                                font-size: 16px;
                                line-height: 20px;
                            }
                            span {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                }
                &.regular-carousel-4 {
                    ul.slick-dots {
                        top: unset;
                        bottom: -60px;
                        left: 50%;
                        transform: translateX(-50%);
                        margin: auto;
                        width: auto;
                        right:unset;
    
                        li {
                            &:first-child {
                                margin-top: 0;
                            }
    
                            display: inline-block;
                            width:9px;
                            margin:0 5px;
    
                            &.slick-active {
                                button {
                                    background-color: $darker-blue;
                                }
                            }
    
                            button {
                                border: 1px solid $darker-blue;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                                padding: 0;
                            }
                        }
                    }
                }
            }
            .committee-container {
                .committee-list {
                    .benefit {
                        .benefit-detail {
                            h4 {
                                font-size: 24px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
            
        }

        .portal-socal {
            .group-inside {
                .group-content {
                    .space-60 {
                        display: none;
                    }
                    .content-detail {
                        &.small-6 {
                            &:last-child {
                                margin-bottom: 30px;
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .profile-img {
                            margin-bottom: 10px;
                        }
                        h5 {
                            font-size: 16px;
                            line-height: 23px;
                        }
                        p {
                            font-size: 14px;
                            line-height: 16px;
                            margin-top:5px;
                        }
                    }
                }
            }
        }

        .connect-group {
            .profile {
                margin-bottom: 60px;
                .profile-detail {
                    .cell {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    label {
                        font-size: 14px;
                        line-height: 14px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 23px;
                    }
                }
            }
        }

        #event {
            .regular-carousel-event ul.slick-dots {
                bottom: -75px;
            }
        }

        .event-detail {
            .space-30 {
                display: none;
            }
            .column-reverse {
                flex-direction: column-reverse;
            }
            .jobs-sidebar {
                margin-top: 45px;
            }
            .conference-socal {
                padding-bottom: 0;
                .regist{
                    &.speaker {
                        .desc {
                            h3 {
                                font-size: 30px;
                                line-height: 33px;
                            }
                            .program-wrapper {
                                label {
                                    &::after {
                                        top:13px;
                                        transform: unset;
                                    }
                                    span {
                                        font-size: 14px;
                                        line-height: 16px;
                                        letter-spacing: 1.17px;
                                        padding: 5px 0;
                                        float: left;
                                        max-width: 70%;
                                    }
                                }
                                h4 {
                                    font-size: 18px;
                                    line-height: 23px;
                                    width: 100%;
                                    float: left;
                                }
                            }

                            .speaker-content {
                                &.align-top {
                                    align-items: center;
                                }
                                .small-3 {
                                    width: 24%;
                                    .img.active {
                                        border: 2px solid $orange;
                                        padding-bottom: calc(80% - 4px);
                                        margin-bottom: 0;
                                    }
                                    .img {
                                        margin-bottom: 0;
                                    }
                                }
                                .small-9 {
                                    width: 76%;
                                    .information {
                                        label {
                                            font-size: 12px;
                                            line-height: 16px;
                                        }
                                        .info-head.h-m {
                                            span {
                                                display: none;
                                            }
                                        }
                                        .info-head {
                                            span {
                                                margin-left: 0;
                                                display: inline-block;
                                                float: none;
                                                display: block;
                                            }
                                            h4 {
                                                margin-bottom: 0;
                                            }
                                        }
                                        .info-head.desktop-hide {
                                            display: inline-block;
                                        }
                                        p {
                                            display: none;
                                        }
                                    }
                                }
                                
                                .other-p {
                                    display: block;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .series-name {
                padding: 14px 13px;
                margin-bottom: 35px;
                span {
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.1px;
                    margin-left: 5px;
                }
            }
            .jobs-content {
                h3 {
                    font-size: 24px;
                    line-height: 27px;
                }
                .series-wrapper {
                    label {
                        font-size: 16px;
                        line-height: 22px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 23px;
                    }
                }
            }
        }

        .curated .member-curated.regular-carousel-3 ul.slick-dots {
            top: unset;
            bottom: -60px;
            right: unset;
            left: 50%;
            margin: auto;
            width: auto;
            transform: translateX(-50%);
        }
        .curated .committee-container .committee-list .benefit.regular-carousel-3 ul.slick-dots {
            top: unset;
            bottom: -60px;
            right: unset;
            left: 50%;
            margin: auto;
            width: auto;
            transform: translateX(-50%);
        }

        .prog-res .regular-carousel-event .slick-slide > div {
            padding-left: 0;
            padding-right:30px;
        }

        
        .committee-container .committee-list
        .regular-carousel-benefit {
            &.benefit {
                .benefit-detail {
                    h4 {
                        height: auto;
                        margin-bottom: 15px;
                    }
                }
            }
            ul.slick-dots {
                top: unset;
                bottom: -70px;
                right: unset;
                left: 50%;
                transform: translateX(-50%);
                margin: auto;

                li {
                    &:first-child {
                        margin-top: 0;
                    }

                    display: inline-block;
                    width:9px;
                    margin:0 5px;

                    &.slick-active {
                        button {
                            background-color: $darker-blue;
                        }
                    }

                    button {
                        border: 1px solid $darker-blue;
                        border-radius: 50%;
                        width: 9px;
                        height: 9px;
                        padding: 0;
                    }
                }
            }
        }
        .m-padding-bottom-2 {
            padding-bottom: 60px!important;
        }
        .m-padding-bottom-0 {
            padding-bottom: 0!important;
        }
        .m-margin-bottom-0 {
            margin-bottom: 0!important;
        }
        .m-padding{
            padding-left: 20px!important;
            padding-right: 20px!important;
        }
        .button-m-membership {
            a {
                width:100%;
                margin-right: 0;
                margin-top: 15px;
            }
        }
    }

    @include breakpoint(medium only) {
        .regular-carousel-event .slick-slide > div {
            padding: 0 15px;
        }
        #event,
        .event {
            &.related-content {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
        .button-m-membership {
            a {
                width:unset;
                margin-right: 5px;
                margin-top: 0;
            }
        }
    }

    @media (min-width: 768px) and (max-width:1024px) { 
        #event,
        .event {
            &.related-content {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
        .regular-carousel-event .slick-slide > div {
            padding: 0 15px;
        }
        .portal-socal .group-inside .group-content .content-detail .profile-img {
            width: 70px;
            height: 70px;
            padding-bottom: 0;
        }

        .conference-socal {
            .regist {
                &.speaker {
                    .desc {
                        .speaker-content {
                            .img {
                                width: 90%;
                                height: 0;
                                margin:auto;
                                margin-bottom: 15px;
                                padding-bottom: 90%;
                                &.active {
                                    padding-bottom: calc(90% - 10px);
                                }
                            }
                            .large-9 {
                                width: 70%;
                            }
                            .large-3 {
                                width: 30%;
                            }
                        }
                    }
                }
            }
        }

        .curated .committee-container .committee-list .benefit .benefit-detail {
            h4 {
                height: auto;
                margin-bottom: 15px;
            }
        }
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
        .curated .committee-container .committee-list .benefit .benefit-detail {
            h4 {
                min-height: 110px;
                height: auto;
                margin-bottom: 15px;
            }
        }
    } 
}

@include breakpoint(medium down) {
    #menu-sidebar-left {
        display: none;
    }

    #menu-sidebar-right {
        margin-top: 45px;

        .summary-cart {
            margin: 0;
        }
    }
}

// custom style

.colorful-grid-height {
    &.five-child {
        .grid-x {
            height: 210px;
        }
    }

    .grid-x {
        height: 260px;

        .colorful-column {
            height: 100%;
            position: relative;
            padding: 0 10px;
            a {
                width: 100%;
                height: 100%;
                display: block;
                position: relative;
            }

            a:focus, a:hover, a:active{
                outline: none;
            }

            &.pink-bg::before {
                content: "";
                background: url(../img/assets/scg-graphic-1@2x.png);
                opacity: 0.5;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: auto;
                width: 60%;
                height: 82%;
                position: absolute;
                z-index: 1;
                background-size: 100%;
            }

            &.light-blue-bg::before {
                content: "";
                background: url(../img/assets/scg-graphic-14@2x.png);
                opacity: 1;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: auto;
                width: 60%;
                height: 82%;
                position: absolute;
                z-index: 1;
                background-size: 100%;
            }

            &.light-orange-bg::before {
                content: "";
                background: url(../img/assets/scg-graphic-15@2x.png);
                opacity: 1;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: auto;
                width: 60%;
                height: 82%;
                position: absolute;
                z-index: 1;
                background-size: 100%;
            }

            &.orange-bg::before {
                content: "";
                background: url(../img/assets/scg-graphic-16@2x.png);
                opacity: 1;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: auto;
                width: 60%;
                height: 82%;
                position: absolute;
                z-index: 1;
                background-size: 100%;
            }

            h3 {
                font-family: $gellerMed;
                font-size: 32px;
                line-height: 36px;
                text-align: center;
                color: $menu-color;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
                z-index: 2;
                position: absolute;
            }
        }

        &.regular-carousel-5, &.regular-carousel-curated {
            margin-bottom: 0;

            .slick-list {
                height: 100%;

                .slick-track {
                    height: 100%;

                    .slick-slide>div {
                        height: 100%;
                    }
                }
            }

            ul {
                &.slick-dots {
                    top: -75px;
                    bottom: unset;
                    right: 60px;
                    width: unset;

                    li {
                        &:first-child {
                            margin-top: 0;
                        }

                        display: inline-block;
                        width:9px;
                        margin:0 5px;

                        &.slick-active {
                            button {
                                background-color: $dark-blue;
                            }
                        }

                        button {
                            border: 1px solid $dark-blue;
                            border-radius: 50%;
                            width: 9px;
                            height: 9px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(medium down) {
        .regular-carousel-curated {
            height:185px;
        }

        &.five-child, &.four-child {
            padding-bottom: 115px !important;

            .grid-x {
                .colorful-column {
                    h3 {
                        font-size: 24px;
                        line-height: 27px;
                    }

                    &::before {
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        height: 100%;
                    }
                }
            }
        }

        &.four-child {
            margin-top:0px!important;
        }

        .grid-x {
                &.regular-carousel-5, &.regular-carousel-curated {
                ul.slick-dots {
                    top: unset;
                    bottom: -70px;
                    right: 0;
                    left: 0;
                    margin: auto;
                }
            }
        }

    }
}

.regular-carousel-curated {
    &, .slick-list {
        width: 100%;
    }
}

.bold-border {
    border-top: 13px solid $green;
    position: relative;
    a:hover {
        label {
            color: $dark-blue;
        }
    }
    &.red-border {
        border-color: $red;
    }

    &.purple-border {
        border-color: $purple-alt;

        h5 {
            color: $purple-alt;
        }
    }

    &.blue-border {
        border-color: $dark-blue;

        h5 {
            color: $dark-blue;
        }
    }

    &.green-border {
        border-color: $green;

        h5 {
            color: $green;
        }
    }

    .badge-c {
        position: absolute;
        right: 19px;
        top: -13px;
        background-color: $orange;
        color: $menu-color;
        padding: 7px 16px;
        font-size: 16px;
        line-height: 20px;
        font-family: $halyard;
        font-weight: 600;
        text-transform: uppercase;
    }

    h5 {
        text-transform: uppercase;
        color: $green;
        letter-spacing: 1.33px;
        font-size: 16px;
        line-height: 16px;
        font-family: $halyard;
        font-weight: 600;
        margin: 25px 0;
    }

    label {
        font-family: $halyard;
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
        text-align: left;
        color: $black;
        margin-bottom: 15px;
        cursor: pointer;
    }

    p {
        font-weight: 300;
        font-family: $halyard;
        color: $black;
        letter-spacing: -0.2px;
        line-height: 26px;
        font-size: 18px;
        margin-bottom: 0px;
        text-align: left;
    }

    a {
        font-family: $halyard;
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: $orange;
        margin-top: 15px;
        display: inline-block;
        &:hover {
            color: $dark-blue;
            svg {
                path {
                    fill: $dark-blue;
                }
            }
        }
        img {
            margin-left: 10px;
            margin-top:-3px;
        }
        svg {
            margin-left: 10px;
            vertical-align: middle;
            margin-top:-3px;
        }
    }
}

.blue-border {
    border-top: 13px solid $dark-blue;
    position: relative;

    .badge-c {
        position: absolute;
        right: 19px;
        top: -13px;
        background-color: $orange;
        color: $menu-color;
        padding: 8px 16px;
        font-size: 16px;
        line-height: 20px;
        font-family: $halyard;
        font-weight: 600;
        text-transform: uppercase;
    }

    h5 {
        text-transform: uppercase;
        color: $dark-blue;
        letter-spacing: 1.33px;
        font-size: 16px;
        line-height: 16px;
        font-family: $halyard;
        font-weight: 600;
        margin: 25px 0;
    }

    label {
        font-family: $halyard;
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
        text-align: left;
        color: $black;
        margin-bottom: 15px;
    }

    p {
        font-weight: 300;
        font-family: $halyard;
        color: $black;
        letter-spacing: -0.2px;
        line-height: 26px;
        font-size: 18px;
        margin-bottom: 0px;
        text-align: left;
    }

    a {
        font-family: $halyard;
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: $orange;
        margin-top: 15px;
        display: inline-block;

        img {
            margin-left: 10px;
        }
    }
}

.orange-border {
    border-top: 13px solid $orange;

    h5 {
        text-transform: uppercase;
        color: $orange;
        letter-spacing: 1.33px;
        font-size: 16px;
        line-height: 16px;
        font-family: $halyard;
        font-weight: 600;
        margin: 25px 0;
    }

    label {
        font-family: $halyard;
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
        text-align: left;
        color: $black;
        margin-bottom: 15px;
        margin-top: 20px;
    }

    p {
        font-weight: 300;
        font-family: $halyard;
        color: $black;
        letter-spacing: -0.2px;
        line-height: 26px;
        font-size: 18px;
        margin-bottom: 15px;
        text-align: left;
    }

    a {
        font-family: $halyard;
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: $orange;
        margin-top: 0;
        display: inline-block;

        img {
            margin-left: 10px;
        }
    }
}

#funders {
    background-color: $menu-color;

    h2 {
        color: $dark-blue;
        font-family: $gellerMed;
        font-size: 40px;
        line-height: 44px;
        text-align: left;
        display: inline-block;
    }

    a {
        font-family: $halyard;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: left;
        color: $orange;
        margin-left: 20px;

        img {
            margin-left: 10px;
        }
    }

    p {
        font-size: 16px;
        line-height: 20px;
        color: $black;
        font-family: $halyard;
        font-weight: 300;
        margin-top: 10px;
    }

    .large-8 {
        margin-top: 15px;

        img {
            width: 75%;
            margin: auto;
            display: table;
        }
    }

    @include breakpoint(medium down) {
        padding-top: 70px;
        padding-bottom: 75px;

        h2 {
            font-size: 30px;
            line-height: 33px;
        }

        a {
            font-size: 16px;
            line-height: 22px;
        }

        p {
            font-size: 12px;
            line-height: 16px;
            font-weight: 300;
            letter-spacing: -0.1px;
            margin-top: 10px;
        }

        .logo-wrapper div {
            margin-bottom: 15px;

            img {
                width: 10rem;
                margin: 0;
            }
        }
    }
}

#notice-copy {
    display: none;

    @include breakpoint(medium down) {
        display: inline-block;
        padding: 30.5px 20px;
        padding-top:0;
        width: 100%;

        a.button {
            background-color: #F4F4F4;
            padding: 15px 20px;
            text-align: left;
            color: #0A00A0;
            font-family: halyard-text, sans-serif;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 17px;
            text-transform: uppercase;
            position: relative;
            margin: 0;

            span:after {
                font-family: "Font Awesome 5 Pro";
                font-weight: 100;
                content: "\f067";
                display: table;
                color: #0A00A0;
                font-size: 24px;
                position: absolute;
                right: 10px;
                top: 15px;
            }
            &.open {
                span:after {
                    content:"\f068";
                }
            }
        }
        .menu-sidebar-m {
            display: none;
            width:100%;
            list-style-type: none;
            margin-left:0;
            position: relative;
            float: left;
            li {
                display: block;
                width: 100%;
                float:left;
                position: relative;
                a {
                    padding: 15px 20px;
                    text-align: left;
                    color: #000;
                    font-family: halyard-text, sans-serif;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 17px;
                    text-transform: uppercase;
                    position: relative;
                    display: block;
                    margin: 0;
                    border-bottom: 1px solid #ccc;
                    &.orange-color {
                        color: $orange;
                    }
                    &.active {
                        color: $dark-blue;
                    }
                }
            }
        }
    }
}

#cta-r {
    display: none;

    @include breakpoint(medium down) {
        display: inline-block;
        padding: 0 20px;
        width: 100%;

        a {
            font-size: 16px;
            line-height: 22px;
            display: block;
            padding: 5px 25px;
            padding-bottom: 7px;
        }
    }
}

#cart {
    #cart-action-mobile {
        display: none;
    }

    @include breakpoint(medium down) {
        #cart-action {
            display: none;
        }

        #cart-action-mobile {
            display: inline-block;
        }

        #order-complete-message {
            padding: 0 !important;
        }
    }
}

.filter-sidebar {
    .portal-sidebar {
        label {
            margin-bottom: 0;
        }

        input {
            margin-bottom: 0;
        }

        .custom-select {
            margin-bottom: 0;
        }

        .search-feature {
            span {
                &:first-child {
                    margin-top: 10px;
                }

                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                font-family: $halyard;
                font-weight: 300;
                color: $black;
                margin-bottom: 0px;
                display: block;
                position: relative;

                &::after {
                    content: "\f00d";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $dark-blue;
                    font-weight: 300;
                    font-size: 18px;
                    font-family: $font-awesome;
                }
            }
        }
    }

    .search-input {
        span {
            position: relative;

            &::after {
                content: "\f002";
                position: absolute;
                font-family: $font-awesome;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotateY(180deg);
                color: $dark-blue;
                font-size: 18px;
            }
        }
    }

    label {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 1.33px;
        font-family: $halyard;
        font-weight: 600;
        color: $dark-blue;
        margin-bottom: 7px;
        text-transform: uppercase;
    }

    input {
        width: 100%;
        display: inline-block;
        border: 0;
        box-shadow: unset;
        padding-left: 0;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        color: $black;
        font-family: $halyard;
        font-weight: 300;
        border-bottom: 1px solid $dark-blue;
        margin-bottom: 45px;

        &:focus {
            box-shadow: unset;
            border: 0;
            border-bottom: 1px solid $dark-blue;
        }

        @include breakpoint(medium down) {
            margin-bottom: 30px;
        }
    }

    .container-box {
        &:first-child {
            margin-top: 18px;
        }

        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 0;
        margin-top:9px;
        cursor: pointer;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        color: $black;
        font-family: $halyard;
        font-weight: 300;
        text-transform: unset;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked~.checkmark {
                background-color: #444;
            }

            &:checked~.checkmark:after {
                display: block;
            }
        }

        .checkmark {
            position: absolute;
            top: 6px;
            left: 0;
            height: 17px;
            width: 16px;
            background-color: transparent;
            border: 2px solid #444;
            border-radius: 2px;
            margin-top: 0;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }

            &:after {
                left: 3px;
                top: 0px;
                width: 6px;
                height: 11px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        &:hover input~.checkmark {
            background-color: #444;
        }
    }

    .container-radio {
        &:first-child {
            margin-top: 18px;
        }

        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 0;
        margin-top:8px;
        cursor: pointer;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        color: $black;
        font-family: $halyard;
        font-weight: 300;
        text-transform: unset;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked~.checkmark {
                background-color: #444;
            }

            &:checked~.checkmark:after {
                display: block;
            }
        }

        .checkmark {
            position: absolute;
            top: 5px;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: transparent;
            border: 1.5px solid #444;
            border-radius: 50%;
            margin-top: 0;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }

            &:after {
                left: 4px;
                right: auto;
                top: 50%;
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                background-color: #fff;
                border-radius: 50%;
            }
        }

        &:hover input~.checkmark {
            background-color: #444;
        }
    }
}

#subscribe {
    padding-top: 90px;
    padding-bottom: 90px;
    position: relative;

    &.subscribe-extended {
        .margin-top-1 {
            h2 {
                margin-top: 5px;
            }
        }
        .large-7 {
            z-index: 2;
        }

        span {
            margin-top: 15px;
            font-weight: 300;
        }

        .form-content {
            margin-top: 40px;
            position: relative;

            label {
                margin-bottom: 0;
            }

            input {
                padding-bottom: 0;
                border-color: $dark-blue;
            }

            .container-box {
                &:first-child {
                    margin-top: 18px;
                }

                display: block;
                position: relative;
                padding-left: 25px;
                margin-bottom: 0;
                margin-top:9px;
                cursor: pointer;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                color: $black;
                font-family: $halyard;
                font-weight: 300;
                text-transform: unset;
                user-select: none;

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                    &:checked~.checkmark {
                        background-color: #444;
                    }

                    &:checked~.checkmark:after {
                        display: block;
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 6px;
                    left: 0;
                    height: 17px;
                    width: 16px;
                    background-color: transparent;
                    border: 2px solid #444;
                    border-radius: 2px;
                    margin-top: 0;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    &:after {
                        left: 3px;
                        top: 0px;
                        width: 6px;
                        height: 11px;
                        border: solid $tosca;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }

                &:hover input~.checkmark {
                    background-color: #444;
                }
            }
        }
    }

    .bg-graphic-newsletter {
        position: absolute;
        left: 0px;
        bottom: 0;
        background-image: url(../img/assets/connect-newsletter-graphic.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: -200px 360px;
        width: 703px;
        height: 100%;
    }

    .large-9 {
        width: 80%;

        @include breakpoint(medium down) {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .large-3 {
        width: 20%;

        @include breakpoint(medium down) {
            width: 100%;
            margin-bottom: 15px;

            .btn-transparent {
                width: 100%;
            }
        }
    }

    h2 {
        color: $dark-blue;
        font-family: $gellerMed;
        font-size: 40px;
        line-height: 44px;
        text-align: left;
        display: inline-block;
    }

    p {
        font-size: 23px;
        line-height: 32px;
        color: $black;
        font-family: $halyard;
        font-weight: 300;
        letter-spacing: -0.19px;
        margin-top: 17px;
    }

    span {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        color: $black;
        font-family: $halyard;
        font-weight: 300;
        margin-top: 33px;
        display: inline-block;

        a {
            font-weight: 600;
            color: $orange;
        }
    }

    input {
        background-color: transparent;
        border-color: $black;
        margin-bottom: 0;
    }

    input::placeholder {
        color: #000;
        opacity: 0.5;
    }

    .btn-transparent {
        color: $dark-blue;
        border-color: $dark-blue;
        font-size: 18px;
        padding: 6px 24px;
        padding-bottom: 7px;
        display: inline-block;
    }

    @include breakpoint(medium down) {
        &.bg-light {
            padding-bottom: 60px;
        }
        padding-top: 60px;
        padding-bottom: 170px;

        h2 {
            font-size: 30px;
            line-height: 33px;
            margin-bottom: 0;
        }

        p {
            font-size: 18px;
            line-height: 23px;
        }

        span {
            font-size: 16px;
            line-height: 23px;
            margin-top: 20px;

            br {
                display: none;
            }
        }

        .form-content {
            .large-6 {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            input {
                font-size: 16px;
                line-height: 24px;
            }

            label {
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 1.17px;
            }

            .custom-select {
                .select-selected {
                    font-size: 16px;
                    line-height: 24px;
                }

                .select-items div {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .btn-transparent {
                display: block;
                font-size: 16px;
                line-height: 22px;
            }
        }

        &.subscribe-extended {
            .form-content {
                .container-box {
                    &:first-child {
                        margin-top: 10px;
                    }

                    font-size: 16px;
                    line-height: 23px;
                    margin-top:5px;
                }
            }
        }

        .bg-graphic-newsletter {
            width: 100%;
            background-size: 85%;
            background-position: top center;
            bottom: 0;
            height: 140px;
        }
    }
}

.btn-transparent {
    border: 1px solid $menu-color;
    border-radius: 20px;
    font-size: 18px;
    font-family: $halyard;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    color: $menu-color;
    padding: 6px 25px;
    padding-bottom: 8px;
    display: inline-block;

    &.blue {
        color: $dark-blue;
        border-color: $dark-blue;
        padding: 6 25px;
        padding-bottom: 8px;
        &:hover {
            background-color: $dark-blue;
            color: $menu-color;
        }
    }

    &.orange {
        color: $orange;
        border-color: $orange;
        padding: 6 25px;
        padding-bottom: 8px;
    }
}

.btn-transparent-small {
    border: 1px solid $menu-color;
    border-radius: 20px;
    font-family: $halyard;
    font-weight: 600;
    text-align: center;
    color: $menu-color;
    padding: 6px 18px;
    padding-bottom: 8px;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;

    &.black {
        border-color: $black;
        color: $black;
    }

    &.light-blue {
        border-color: $health;
        color: $health;
    }

    &.light-green {
        border-color: $light-green;
        color: $light-green;
    }
    &.purple {
        border-color: $equity;
        color: $equity;
    }
    &.dark-orange {
        border-color: $disaster;
        color: $disaster;
    }
}

.btn-orange {
    border: 1px solid $orange;
    border-radius: 20px;
    font-family: $halyard;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    color: $menu-color;
    padding: 6px 25px;
    padding-bottom: 8px;
    background-color: $orange;
}

.btn-orange:hover {
    background-color: transparent;
    color: $orange;
}

#heading .profile-pic {
    @media (min-width:640px) {
        text-align: right;
        img {
            margin-bottom: 0;
            max-height: 330px;
        }
    }
}

.block-style {
    width: 100%;
    display: block;
}

.divider-line {
    width: 100%;
    display: block;
    border-top: 1px solid $dark-blue;
    margin-top: 60px;

    &.grey {
        border-color: $light-grey;
    }
}

.custom-select {
    position: relative;
    margin-bottom: 45px;

    select {
        display: none;
        /*hide original SELECT element:*/
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        font-family: $halyard;
        font-weight: 300;
    }

    /*style the items (options), including the selected item:*/
    .select-selected {
        color: $black;
        padding: 8px 16px;
        border: 0;
        border-bottom: 1px solid $dark-blue;
        cursor: pointer;
        user-select: none;
        padding-left: 0;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        font-family: $halyard;
        font-weight: 300;
    }

    /*style the arrow inside the select element:*/
    .select-selected:after {
        position: absolute;
        content: "";
        top: 10px;
        right: 14px;
        width: 0;
        height: 0;
        font-family: $font-awesome;
        font-weight: 400;
        content: "\f107";
        margin-bottom: 20px;
    }

    /*point the arrow upwards when the select box is open (active):*/
    .select-selected.select-arrow-active:after {
        content: '\f106';
    }

    /*style items (options):*/
    .select-items {
        position: absolute;
        background-color: #fff;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        border: 1px solid $dark-blue;
        border-top: 0;

        div {
            padding: 10px 20px;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            font-family: $halyard;
            font-weight: 300
        }

        div:hover {
            background-color: rgba(242, 104, 0, 0.2);
        }

        .same-as-selected {
            background-color: rgba(242, 104, 0, 0.2);
            font-weight: 600
        }
    }

    /*hide the items when the select box is closed:*/
    .select-hide {
        display: none;
    }
}

.accordion,
.accordion-sidebar {
    dt {
        padding: 0px;
        border-bottom: 1px solid #ccc;

        label {
            position: relative;
            font-size: 18px;
            line-height: 26px;
            font-family: $halyard;
            color: $black;
            font-weight: 600;
            letter-spacing: -0.2px;
            display: block;
            padding: 20px 0;
            cursor: pointer;
            padding-right: 30px;

            &:after {
                position: absolute;
                content: "";
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                font-family: $font-awesome;
                font-weight: 100;
                content: "\f067";
                display: table;
                color: $dark-blue;
                font-size: 24px;
            }

            &.active::after {
                position: absolute;
                content: "";
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                font-family: $font-awesome;
                font-weight: 100;
                content: "\f068";
                display: table;
                color: $dark-blue;
                font-size: 24px;
            }
        }


    }

    p {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        font-family: $halyard;
        font-weight: 300;
        padding-top: 20px;
        display: none;
    }
}




//background

.bg-white {
    background-color: #fff;
}


.bg-light {
    background-color: $menu-color;
}

.pink-bg {
    background-color: $pink;
}

.blue-bg {
    background-color: $alt-blue;
}

.light-blue-bg {
    background-color: $light-blue;
}

.light-orange-bg {
    background-color: $light-orange;
}

.orange-bg {
    background-color: $dark-orange;
}

.tosca-bg {
    background-color: $tosca;
}

//SCG ACCESSEBILITY NEW
.bg-curr-art{
    background-color: $art;
    &::before{
        content: "";
        background: url(../img/curated/scg-graphic-1.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-child{
    background-color: $child;
    &::before{
        content: "";
        background: url(../img/curated/scg-graphic-2.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.5;
    }
}
.bg-curr-economy{
    background-color: $economy;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-3.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-criminal{ 
    background-color:$criminal;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-4.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.5;
    }
}
.bg-curr-disaster{ 
    background-color:$disaster;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-5.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-edu{ 
    background-color:$education;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-6.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-env{ 
    background-color:$environment;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-7.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-equity{ 
    background-color:$equity;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-8.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-health{ 
    background-color:$health;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-9.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-housing{ 
    background-color:$housing;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-10.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-journal{ 
    background-color:$journalism;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-11.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-public{ 
    background-color:$public;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-12.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-youth{ 
    background-color:$youth;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-13.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.bg-curr-aging{
    background-color:$aging;
     &::before{
        content: "";
        background: url(../img/curated/scg-graphic-2.svg);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin: auto;
        width: 75%;
        height: 75%;
        position: absolute;
        z-index: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.mid-orange-bg {
    background-color: $mid-orange;
}


@include breakpoint(medium down) {
    .accordion-sidebar {
        dt {
            label {
                color: $dark-blue;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 1.2px;
                margin-bottom: 16px;
                padding: 0;
            }
        }

        .panel {
            padding-top: 20px;
        }
    }

    .bold-border {
        border-top: 10px solid $green;

        .badge-c {
            font-size: 12px;
            line-height: 15px;
            padding: 4px 13px;
            padding-top: 7px;
            right:10px;
        }

        h5 {
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 1.17px;
            margin: 18px 0;
        }

        label {
            font-size: 18px;
            line-height: 23px;
        }

        p {
            font-size: 16px;
            line-height: 23px;
            letter-spacing: -0.2px;
        }
    }
}

.regular-carousel-3 .slick-list {
    width: 100%;
}



main.body-socal .conference-socal .regist.speaker .desc .speaker-content .information .info-head.desktop-hide {
    display: none;
}
.desktop-hide {
    display: none;
}

.module-signin {
    width:100%;
    position: fixed;
    bottom: 0;
    z-index: 999;

    .container-wrapper {
        position: relative;
    }
    
    .content {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #00D4D5;
        padding: 23px 18px 30px 18px;
        max-width: 308px;
        transition: transform 0.3s ease-out;
        will-change: transform;
        transform: translateY(100%);

        @media (max-width:767px) {
            width: 100%;
            max-width: 100%;
            position: fixed;
            bottom:0;
            left:0;
        }
        label {
            font-size: 23px;
            line-height: 30px;
            font-family: $halyard;
            color: #000;
            font-weight: 600;
            margin-bottom: 11px;
        }
        p {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            font-family: $halyard;
            font-weight: 300;
            margin-bottom: 30px;
        }
        a {
            display: block;
            &:hover {
                background-color: $dark-blue;
                color: $menu-color;
            }
        }
        .icon-close {
            position: absolute;
            top: 19px;
            right: 16px;
            cursor:pointer;
        }
    }

    &.active .content {
        transform: translateY(0);
    }
}

.screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.search-modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $dark-blue;
    padding-top: 60px;
    padding-bottom: 50px;
    transition: all 250ms;
    transform: translateY(-100%);
    .search-toggle-close {
        cursor: pointer;
        position: fixed;
        top: 20px;
        font-size: 25px;
        color: #fff;
        right: 20px;
        @media (min-width: 767px) {
            right: 60px;
        }
    }
    img {
        max-width: 211px;
        @media (min-width: 767px) {
            max-width: 405px;
        }
    }
    form {
        padding-top: 50px;
    }
    .grid-x {
    }
    fieldset {
        display: flex;
        position: relative;
    }
    input[type="text"] {
        border: 0;
        border-bottom: 1px solid #FFFCF2;
        color: #FFFCF2;
        background-color: transparent;
        font-size: 40px;
        font-family: $gellerMed;
        line-height: 44px;
        color: $menu-color;
        margin-top: 15px;
        padding:  10px 50px 10px 0;
        height:  auto;
        text-overflow: ellipsis;
    }
    input[type="text"]:focus {
        outline: none;
        box-shadow: none;
    }
    input[type="submit"] {
        width: 30px;
        height: 90px;
        position: absolute;
        right: 0;
        top:  0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
    }
    ::placeholder {
        opacity: 1;
        color: rgba(#FFFCF2, 0.7);
    }
    .fa-search {
        color: #fff;
        position: absolute;
        right: 0;
        top: 40px;
        opacity: 1;
        font-size: 25px;
        display: inline-block;
        transform: scaleX(-1);
    }
}

.search-modal-overlay {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.8);
}

.search-modal--is-showing {
  .search-modal {
    transform: translateY(0%);
  }
  .search-modal-overlay {
    display: block;
  }
}
