@import './global/variable';

footer.footer-socal {
    background-color: $dark-blue;

    .main-footer {
        padding: 45px 0px;
        @include breakpoint(medium down) {
            padding:60px 0;
        }
        ul {
            list-style-type: none;
            margin-left: 0;

            li a {
                display: block;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 1.2px;
                font-family: halyard-text, sans-serif;
                font-weight: 600;
                font-style: normal;
                color: $menu-color;
                margin: 10px 0;
                text-transform: uppercase;
            }

            li:first-child a {
                margin-top: 0;
            }

            li:last-child a {
                margin-bottom: 0;
            }
        }

        p {
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            font-family: $halyard;
            font-weight: 300;
            letter-spacing: -0.2px;
            color: $menu-color;
            margin-bottom: 12px;
        }

        img {
            width: 80%;
            @include breakpoint(medium down) {
                display: none;
            }
        }

        #footer-info{
            @include breakpoint(medium down) {
                margin-top:60px;
            }
        }

        .social-icon {
            width:100%;
            display: inline-block;
            margin-top:30px;
            a {
                display: inline;
                margin-right: 15px;
                i {
                    font-size:24px;
                    line-height: 24px;
                    color: $menu-color;
                }
            }
        }
    }

    .bottom-bar {
        border-top: 1px solid $menu-color;
        padding: 15px 0;

        p {
            margin: 0;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            font-family: $halyard;
            font-weight: 300;
            letter-spacing: -0.2px;
            color: $menu-color;
        }

        a {
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            font-family: $halyard;
            font-weight: 300;
            letter-spacing: -0.2px;
            color: $menu-color;
        }
    }

}